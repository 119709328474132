// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import useJwt from '@src/auth/jwt/useJwt'
import { closeSwal, handleError, handleLoading, toastSuccess } from '../../sweetalert'

export const getData = createAsyncThunk('department/getData', async params => {
  try {
    const response = await useJwt.getListDepartment(params)
    return { 
      allData: response.data.data,
      data: response.data.data,
      totalPages: response.data.total,
      params 
    }
  } catch (error) {
    useJwt.handleError(error)
    return {
      allData: [], 
      data: [], 
      totalPages: 0, 
      params 
    }
  }
})

export const getDepartment = createAsyncThunk('department/getDepartment', async params => {
  try {
    const response = await useJwt.getDepartment(params)
    return { 
      status: true,
      data: response.data.data, 
    }
  } catch (error) {
    useJwt.handleError(error)
    return {
      status: false
    }
  }
})

export const createDepartmentForInfo = createAsyncThunk('department/create', async params => {
  try {
    const response = await useJwt.createDepartmentForInfo(params)
    return {
      status: true,
      response: response.data
    }
  } catch (error) {
    useJwt.handleError(error)
    return {
      status: false,
    }
  }
})

export const updateDepartmentPermission = createAsyncThunk('department/updatePermission', async params => {
  try {
    const response = await useJwt.updateDepartmentForPermission(params)
    return {
      status: true,
      response: response.data
    }
  } catch (error) {
    useJwt.handleError(error)
    return {
      status: false,
    }
  }
})

export const editDepartment = createAsyncThunk('department/edit', async (params, {dispatch, getState}) => {
  try {
    params.setBlock(true)
    const response = await useJwt.editDepartment(params.data)
    params.setBlock(false)
    toastSuccess("Chỉnh sửa thành công")
    if (params.checkCreate) {
        params.navigateFunction()
    }
    dispatch(
      getDepartment(getState().department.paramsDepartment)
    )
  } catch (error) {
    closeSwal()
    useJwt.handleError(error)
    params.reset()
  }
})

export const deleteDepartment = createAsyncThunk('department/delete', async (params, {getState, dispatch}) => {
  try {
    handleLoading("Vui lòng chờ hệ thống...")
    await useJwt.deleteDepartment(params)
    const store = getState().department
    await dispatch(getData(store.params))
    closeSwal()
    toastSuccess("Xóa thành công")
  } catch (error) {
    closeSwal()
    useJwt.handleError(error)
  }
})

export const departmentSlice = createSlice({
  name: 'department',
  initialState: {
    data: [],
    total: 1,
    params: {},
    limit: 10,
    search: '',
    isLoadingForSelect: false, // Property extend, using for select department-create department feature
    allData: [],
    departmentChoice: {},
    paramsDepartment: {}
  },
  reducers: {
    setLimit: (state, action) => {
      return {
        ...state,
         limit: action.payload
      }
    },
    setSearch: (state, action) => {
      return {
        ...state, 
        search: action.payload
      }
    },
    setIsLoadingForSelect: (state, action) => {
      return {
        ...state,
        isLoadingForSelect: action.payload
      }
    }
  },
  extraReducers: builder => {
    builder.addCase(getData.fulfilled, (state, action) => {
      state.data = action.payload.data
      state.params = action.payload.params
      state.allData = action.payload.allData
      state.total = action.payload.totalPages
    })
    .addCase(getDepartment.fulfilled, (state, action) => {
      state.departmentChoice = action.payload.departmentChoice
      state.paramsDepartment = action.payload.paramsDepartment
    })
  }
})

export default departmentSlice.reducer

export const {setLimit, setSearch, setIsLoadingForSelect} = departmentSlice.actions
