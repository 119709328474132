// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import useJwt from '@src/auth/jwt/useJwt'

// ** Axios Imports
import axios from 'axios'

export const getData = createAsyncThunk('requests/getData', async params => {
  try {
    const response = await useJwt.getPaymentRequestList(params)
    return {
      params,
      data: response.data,
      totalPages: response.total
    }
  } catch (error) {
    console.log(error)
    useJwt.handleError(error)
    return {
      allData: [], 
      data: [], 
      totalPages: 1, 
      params 
    }
  }
})
export const getOne = createAsyncThunk('requests/getOne', async id => {
  console.log(id)
  try {
    const response = await useJwt.getPaymentRequest({id: id})
    console.log(response)
    return response.data.data
  } catch (error) {
    useJwt.handleError(error)
  }
})
export const addRequest = createAsyncThunk('requests/addRequest', async (params, { dispatch, getState }) => {
  try {
    const response = await useJwt.createPaymentRequest(params)
    console.log(response)
    await dispatch(getData(getState().params.params))
    return params
  } catch (error) {
    useJwt.handleError(error)
  }
})
export const updateRequestStatus = createAsyncThunk('requests/updateStatus', async (params, { dispatch, getState }) => {
  try {
    const response = await useJwt.updatePaymentRequestStatus(params)
    await dispatch(getOne(params.id))
    return params.data
  } catch (error) {
    useJwt.handleError(error)
  }
})

export const requestSlice = createSlice({
  name: 'request',
  initialState: {
    data: [],
    total: 1,
    params: {},
    allData: [],
    selected: null
  },
  reducers: {},
  extraReducers: builder => {
    builder
      // .addCase(getAllData.fulfilled, (state, action) => {
      //   state.allData = action.payload
      // })
      .addCase(getData.fulfilled, (state, action) => {
        // console.log(action.payload.data)
        state.data = action.payload.data
        state.params = action.payload.params
        state.total = action.payload.totalPages
      })
      .addCase(getOne.fulfilled, (state, action) => {
        console.log(action.payload)
        state.selected = action.payload
      })
  }
})

export default requestSlice.reducer