// ** Reducers Imports
import navbar from './navbar'
import layout from './layout'
import auth from './authentication'
import todo from '@src/views/apps/todo/store'
import chat from '@src/views/apps/chat/store'
import users from '@src/views/apps/user/store'
import email from '@src/views/apps/email/store'
import kanban from '@src/views/apps/kanban/store'
import invoice from '@src/views/apps/invoice/store'
import calendar from '@src/views/apps/calendar/store'
import ecommerce from '@src/views/apps/ecommerce/store'
import dataTables from '@src/views/tables/data-tables/store'
import permissions from '@src/views/apps/roles-permissions/store'
import promotions from '@src/views/apps/promotions/store'
import suppliers from '@src/views/pages/foster/suppliers/store'
import suppliersDevice from '@src/views/pages/foster/suppliers_device/store'
import deviceStatus from '@src/views/pages/foster/status_devices/store'
import roles from '@src/views/pages/foster/roles/store'
import positions from '@src/views/pages/foster/roles/storePositions'
import department from '@src/views/pages/foster/department/store'
import unit from '@src/views/pages/foster/unit/store'
import account from '@src/views/pages/account-settings/store'
import products from '@src/views/pages/foster/products/store'
import tag from '@src/views/pages/foster/tag/store'
import paymentMethod from '@src/views/pages/foster/payment_method/store'
import types from '@src/views/pages/foster/type/store'
import brands from '@src/views/pages/foster/brand/store'
import resourceProducts from '@src/views/pages/foster/resource_products/store' 
import device from '@src/views/pages/devices/store'
import deviceHistory from '@src/views/pages/devices/history/store'
import request from '@src/views/pages/foster/payment_request/store'
import taxs from '@src/views/pages/foster/tax/store' 
import properties from '@src/views/pages/foster/property/store' 
import pricePolicy from '@src/views/pages/foster/price_policy/store' 
import branch from '@src/views/pages/foster/branch/store'
import stocks from '@src/views/pages/foster/stock/store'
import discounts from '@src/views/pages/foster/discount/store'
import importStock from '@src/views/pages/foster/stock/ImportStock/store'
import inventoryStock from '@src/views/pages/foster/stock/Inventory/store'
import exportStock from '@src/views/pages/foster/stock/ExportStock/store'
import permissionMatrix from '@src/views/pages/foster/permission_matrix/store'

const rootReducer = {
  // auth,
  todo,
  chat,
  email,
  users,
  kanban,
  navbar,
  layout,
  invoice,
  calendar,
  ecommerce,
  dataTables,
  permissions,
  suppliers,
  suppliersDevice,
  deviceStatus,
  roles,
  department,
  unit,
  account,
  products,
  promotions,
  tag,
  types,
  brands,
  resourceProducts,
  device,
  deviceHistory,
  request,
  taxs,
  properties,
  pricePolicy,
  branch,
  stocks,
  discounts,
  importStock,
  paymentMethod,
  inventoryStock,
  exportStock,
  permissionMatrix,
  positions
}

export default rootReducer
