import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import useJwt from '@src/auth/jwt/useJwt'

export const getData = createAsyncThunk('position/getData', async (params) => {
  try {
    const response = await useJwt.getListPositions(params)
    return {
      data: response.data.data,
      total: response.data.total_page,
      params
    }
  } catch (error) {
    useJwt.handleError(error)
    return {
      data: [],
      total: 0,
      params
    }
  }
})

export const getPosition = createAsyncThunk('position/getPosition', async (params) => {
  try {
    const response = await useJwt.getPosition(params)
    return {
      status: true,
      data: response.data.data
    }
  } catch (error) {
    useJwt.handleError(error)
    return {
      status: false,
    }
  }
})

export const createPosition = createAsyncThunk('position/createPosition', async (params) => {
  try {
    const response = await useJwt.addPosition(params)
    return {
      status: true,
      data: response.data.data
    }
  } catch (error) {
    useJwt.handleError(error)
    return {
      status: false,
    }
  }
})

export const updatePosition = createAsyncThunk('position/updatePosition', async (params) => {
  try {
    const response = await useJwt.updatePositionInfo(params)
    return {
      status: true,
      data: response.data.data
    }
  } catch (error) {
    return {
      status: false
    }
  }
})

export const updatePermissionForPosition = createAsyncThunk('position/updatePermissionForPosition', async (params) => {
  try {
    const response = await useJwt.updatePermissionForPosition(params)
    return {
      status: true,
      data: response.data.data
    }
  } catch (error) {
    useJwt.handleError(error)
    return {
      status: false,
    }
  }
})

export const deletePosition = createAsyncThunk('position/deletePosition', async (params) => {
  try {
    const response = await useJwt.deletePosition(params)
    return {
      status: true
    }
  } catch (error) {
    useJwt.handleError(error)
    return {
      status: false
    }
  }
})

const positionSlice = createSlice({
  name: 'positions',
  initialState: {
    data: [],
    total: 0,
    params: null,
  },
  reducers: {

  },
  extraReducers: builder => {
    builder.addCase(getData.fulfilled, (state, action) => {
      state.data = action.payload.data
      state.total = action.payload.total
      state.params = action.payload.params
    })
  }
})

export default positionSlice.reducer