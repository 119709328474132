// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Axios Imports
import axios from 'axios'
import useJwt from '@src/auth/jwt/useJwt'
import toast from 'react-hot-toast'

export const getData = createAsyncThunk('promotions/getData', async params => {
  const response = await useJwt.getListCartPriceRule()
  console.log("🚀 ~ file: index.js:10 ~ getData ~ response:", response)
  return {
    total: response.data.total,
    params: response.data.params,
    allData: response.data.data,
    data: response.data.data
  }
})

export const getCartPriceRuleById = createAsyncThunk(
  'promotions/getPromotionById',
  async (id, { dispatch, getState }) => {
    const response = await useJwt.getCartPriceRuleById(id)
    return {
      selected: response.data.data
    }
  }
)

export const addCartPriceRule = createAsyncThunk(
  'promotions/addPromotion',
  async (data, { dispatch, getState }) => {
    await useJwt.createCartPriceRule(data)
    await dispatch(getData(getState().promotions.params))
    return data
  }
)

export const updateCartPriceRule = createAsyncThunk(
  'promotions/updatePromotion',
  async ({ id, data }, { dispatch, getState }) => {
    await useJwt.updateCartPriceRule(id, data)
    await dispatch(getData(getState().promotions.params))
    return { id }
  }
)

export const deleteCartPriceRule = createAsyncThunk('promotions/deletePromotion', async (id, { dispatch, getState }) => {
  await axios.delete('/apps/promotions/delete', { id })
  await dispatch(getData(getState().promotions.params))
  return id
})

export const promotionsSlice = createSlice({
  name: 'promotions',
  initialState: {
    data: [],
    total: 1,
    params: {},
    allData: [],
    selected: null
  },
  reducers: {
    selectPriceCartRule: (state, action) => {
      state.selected = action.payload
    },
    getPriceCartRuleInfo: (state, action) => {
      const id = action.payload;
      if(state.selected?.id !== id) {
        const _data = state.allData.find(_ => _.id === id)
        
        state.selected = _data
      }
    }
  },
  extraReducers: builder => {
    builder.addCase(getData.fulfilled, (state, action) => {
      state.data = action.payload?.data
      state.total = action.payload?.total
      state.params = action.payload?.params
      state.allData = action.payload?.allData
    })
    .addCase(getCartPriceRuleById.fulfilled, (state, action) => {
      state.selected = action.payload?.selected
    })
    .addCase(updateCartPriceRule.fulfilled, () => {
      toast.success('Chỉnh sửa quy tắc thành công!')
    })
    .addCase(addCartPriceRule.fulfilled, () => {
      toast.success('Thêm quy tắc thành công!')
    })
  }
})

export const { selectPriceCartRule, getPriceCartRuleInfo } = promotionsSlice.actions

export default promotionsSlice.reducer
