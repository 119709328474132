// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import useJwt from '@src/auth/jwt/useJwt'
import { closeSwal, handleError, handleLoading, toastSuccess } from '../../sweetalert'

export const getData = createAsyncThunk('stocks/getData', async params => {
  try {
    if (params.setIsLoading !== undefined) {
      params.setIsLoading(true)
    }
    const response = await useJwt.getListStock(params)
    let data = []
    const request = response.data.data?.map(async item => {
      const response_2 = await useJwt.getStock({
        id: item.id
      })
      data.push({
        ...response_2.data.data
      })
      
    })
    await Promise.all(request)
    if (params.setIsLoading !== undefined) {
      params.setIsLoading(false)
    }
    return { 
      allData: data, 
      data, 
      totalPages: response.data.total_page, 
      params 
    }
    
  } catch (error) {
    useJwt.handleError(error)
    if (params.setIsLoading !== null && params.setIsLoading !== undefined)
      params.setIsLoading(false)
    return {
      allData: [], 
      data: [], 
      totalPages: 1, 
      params 
    }
  }
})

export const getStock = createAsyncThunk('stocks/getStock', async params => {
  try {
    params.setBlock(true)
    const response = await useJwt.getStock(params)
    params.setBlock(false)
    return { 
      stockChoice: response.data.data, 
      paramsStock: params
    }
  } catch (error) {
    useJwt.handleError(error)
    return {
      stockChoice: {},
      paramsStock: params
    }
  }
})

export const createStock = createAsyncThunk('stocks/create', async (params, {dispatch, getState}) => {
  try {
    params.setBlock(true)
    await useJwt.addBrand(params.data)
    params.setBlock(false)
    toastSuccess("Tạo thành công")
    if (!params.checkCreate) {
      params.navigateFunction()
    }
    if (params.isReload !== undefined && params.isReload === true) {
      dispatch(getData(getState().brands.params))
    }
    params.reset()
  } catch (error) {
    useJwt.handleError(error)
  }
})

export const editStock = createAsyncThunk('stocks/edit', async (params, {dispatch, getState}) => {
  try {
    params.setBlock(true)
    const response = await useJwt.editBrand(params.data)
    params.setBlock(false)
    toastSuccess("Chỉnh sửa thành công")
    if (params.checkCreate) {
        params.navigateFunction()
    }
    dispatch(
      getBrand(getState().brands.paramsBrand)
    )
  } catch (error) {
    closeSwal()
    useJwt.handleError(error)
    params.reset()
  }
})

export const deleteStock = createAsyncThunk('stocks/delete', async (params, {getState, dispatch}) => {
  try {
    handleLoading("Vui lòng chờ hệ thống...")
    await useJwt.deleteBrand(params)
    const store = getState().brands
    await dispatch(getData(store.params))
    closeSwal()
    toastSuccess("Xóa thành công")
  } catch (error) {
    closeSwal()
    useJwt.handleError(error)
  }
})

export const stockSlice = createSlice({
  name: 'stocks',
  initialState: {
    data: [],
    total: 1,
    params: {},
    allData: [],
    stockChoice: {},
    paramsStock: {}
  },
  reducers: {},
  extraReducers: builder => {
    builder.addCase(getData.fulfilled, (state, action) => {
      state.data = action.payload.data
      state.params = action.payload.params
      state.allData = action.payload.allData
      state.total = action.payload.totalPages
    })
    .addCase(getStock.fulfilled, (state, action) => {
      state.stockChoice = action.payload.stockChoice
      state.paramsStock = action.payload.paramsStock
    })
  }
})

export default stockSlice.reducer
