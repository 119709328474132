// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import useJwt from '@src/auth/jwt/useJwt'
import { closeSwal, handleError, handleLoading, toastSuccess } from '../../sweetalert'

export const getData = createAsyncThunk('brands/getData', async params => {
  try {
    params.setIsLoading(true)
    const response = await useJwt.getListBrands(params)
    params.setIsLoading(false)
    return { 
      allData: response.data.data, 
      data: response.data.data, 
      totalPages: response.data.total_page, 
      params 
    }
    
  } catch (error) {
    useJwt.handleError(error)
    if (params.setIsLoading !== null && params.setIsLoading !== undefined)
      params.setIsLoading(false)
    return {
      allData: [], 
      data: [], 
      totalPages: 1, 
      params 
    }
  }
})

export const getBrand = createAsyncThunk('brands/getBrand', async params => {
  try {
    params.setBlock(true)
    const response = await useJwt.getBrand(params)
    params.setBlock(false)
    return { 
      brandChoice: response.data.data, 
      paramsBrand: params
    }
  } catch (error) {
    useJwt.handleError(error)
    return {
      BrandChoice: {},
      paramsBrand: params
    }
  }
})

export const createBrand = createAsyncThunk('brands/create', async (params, {dispatch, getState}) => {
  try {
    params.setBlock(true)
    await useJwt.addBrand(params.data)
    params.setBlock(false)
    toastSuccess("Tạo thành công")
    if (!params.checkCreate) {
      params.navigateFunction()
    }
    if (params.isReload !== undefined && params.isReload === true) {
      dispatch(getData(getState().brands.params))
    }
    params.reset()
  } catch (error) {
    useJwt.handleError(error)
  }
})

export const editBrand = createAsyncThunk('brands/edit', async (params, {dispatch, getState}) => {
  try {
    params.setBlock(true)
    const response = await useJwt.editBrand(params.data)
    params.setBlock(false)
    toastSuccess("Chỉnh sửa thành công")
    if (params.checkCreate) {
        params.navigateFunction()
    }
    dispatch(
      getBrand(getState().brands.paramsBrand)
    )
  } catch (error) {
    closeSwal()
    useJwt.handleError(error)
    params.reset()
  }
})

export const deleteBrand = createAsyncThunk('brands/delete', async (params, {getState, dispatch}) => {
  try {
    handleLoading("Vui lòng chờ hệ thống...")
    await useJwt.deleteBrand(params)
    const store = getState().brands
    await dispatch(getData(store.params))
    closeSwal()
    toastSuccess("Xóa thành công")
  } catch (error) {
    closeSwal()
    useJwt.handleError(error)
  }
})

export const brandSlice = createSlice({
  name: 'brands',
  initialState: {
    data: [],
    total: 1,
    params: {},
    allData: [],
    typeChoice: {},
    paramsType: {}
  },
  reducers: {},
  extraReducers: builder => {
    builder.addCase(getData.fulfilled, (state, action) => {
      state.data = action.payload.data
      state.params = action.payload.params
      state.allData = action.payload.allData
      state.total = action.payload.totalPages
    })
    .addCase(getBrand.fulfilled, (state, action) => {
      state.brandChoice = action.payload.brandChoice
      state.paramsBrand = action.payload.paramsBrand
    })
  }
})

export default brandSlice.reducer
