import { env } from "@env"

// ** Auth Endpoints
export default {
// ** LINK
  // endPoint: 'https://foster-be.onrender.com',
  // endPoint: 'http://192.168.1.21:8002',
 endPoint: env.REACT_APP_API_ENDPOINT,
  //  endPoint: 'http://localhost:8002',
  loginEndpoint: '/api/v1/auth/login',
  registerEndpoint: '/api/v1/auth/register',
  refreshEndpoint: '/api/v1/auth/refresh',
  logoutEndpoint: '/jwt/logout',
  getMeEndPoint: '/api/v1/users/me',

  tokenType: 'Bearer',
  storageTokenKeyName: 'foster:accessToken',
  storageRefreshTokenKeyName: 'foster:refreshToken',

  storageUserDataKeyName: 'foster:userData',

  // Department
  linkCreateDepartment: '/apps/user/create-department-management',
  linkListDepartment: '/apps/user/department-management',
  
// ** APIs
  // Suppliers
  getListSuppliersEndPoint: (params) => `/api/v1/suppliers?search=${params.q}&page=${params.page}&size=${params.perPage}&order_by=id%20DESC&name=&code=&supplier_type=${params.supplierType}&email=&phone=&address=&status=${params.supplierStatus}&is_deleted=false`,
  getSupplierEndPoint: (params) => `/api/v1/suppliers/${params.id}`,
  createSupplierEndPoint: `/api/v1/suppliers`,
  editSupplierEndPoint: (params) => `/api/v1/suppliers/${params.id}`,
  deleteSupplierEndPoint: (params) => `/api/v1/suppliers/${params.id}`,

  // Status devices
  getListStatusDevicesEndPoint: `/api/v1/device-status`,
  getStatusDeviceEndPoint: (params) => `/api/v1/device-status/${params.id}`,
  createStatusDeviceEndPoint: `/api/v1/device-status`,
  editStatusDeviceEndPoint: (params) => `/api/v1/device-status/${params.id}`,
  deleteStatusDeviceEndPoint: (params) => `/api/v1/device-status/${params.id}`,

  // Users
  getListUsersEndPoint: (params) => `/api/v1/users?search=${params.q}&page=${params.page}&size=${params.perPage}&order_by=id%20DESC&is_active=true`,
  addUserEndPoint: `/api/v1/users`,
  getUserEndPoint: (params) => `/api/v1/users/${params.id}`,
  editUserEndPoint: (params) => `/api/v1/users/${params.id}`,
  deleteUserEndPoint: (params) => `/api/v1/users/${params.id}`,
  getRoleEndPoint: (params) => `/api/v1/roles/${params.id}`,
  updateInfoMe: `/api/v1/users/me`,
  updateAvatarMe: `/api/v1/users/update-avatar/me`,
  updatePassword: `/api/v1/users/change-password/me`,

  // Unit
  getListUnitsEndPoint: (params) => `/api/v1/units?page=${params.page}&size=${params.perPage}&order_by=id%20DESC`,
  getUnitEndPoint: (params) => `/api/v1/units/${params.id}`,
  createUnitEndPoint: `/api/v1/units`,
  editUnitEndPoint: (params) => `/api/v1/units/${params.id}`,
  deleteUnitEndPoint: (params) => `/api/v1/units/${params.id}`,
  
  // department
  getListDepartmentsEndPoint:`/api/v1/departments`,
  getDepartmentEndPoint: (params) => `/api/v1/departments/${params.id}`,
  createDepartmentForInfoEndPoint: `/api/v1/departments`,
  updateDepartmentForPermissionEndPoint: (params) => `/api/v1/departments/${params.id}/enable-permission-config`,
  editDepartmentEndPoint: (params) => `/api/v1/departments/${params.id}`,
  deleteDepartmentEndPoint: (params) => `/api/v1/departments/${params.id}`,
  
  // Roles
  getListRolesEndPoint: (params) => `/api/v1/roles?page=${params.page}&size=${params.perPage}&order_by=id%20DESC`,
  getPermissionMatrixEndPoint: `/api/v1/admin/permission-matrix`,
  getRoleAdminEndPoint: (params) => `/api/v1/admin/roles/${params.id}/permission-matrix`,
  updateRoleEndPoint: (params) => `/api/v1/roles/${params.id}`,
  postNewRoleEndPoint: `/api/v1/roles`,
  updatePermissionForRoleEndPoint: (params) => `/api/v1/admin/roles/${params.id}/enable-permission-config`,
  deleteRoleEndPoint: (params) => `/api/v1/roles/${params.id}`,
  // Device
  getListDevicesEndPoint: (params) => `/api/v1/devices?search=${params.search}&page=${params.page}&size=${params.size}&order_by=${params.sortColumn}%20${params.sortDir}&status_id=${params.status[0]}&status_id=${params.status[1]}&status_id=${params.status[2]}&status_id=${params.status[3]}&is_deleted=${params.is_deleted}`,
  getDeviceEndPoint: (params) => `/api/v1/devices/${params.id}`,
  // Resources
  uploadResourceEndPoint: () => `/api/v1/resources`,
  // Device history
  getAllDeviceHistoryEndPoint: (params) => `/api/v1/device-histories?device_id=${params.id}`,
  getDeviceHistoryEndPoint: (params) => `/api/v1/device-histories?search=${params.search}&page=${params.page}&size=${params.size}&order_by=${params.sortColumn}%20${params.sortDir}&device_id=${params.id}`,
  addDeviceHistoryEndPoint: () => `/api/v1/device-histories`,
  getOneHistoryEndPoint: (params) => `/api/v1/device-histories/${params.id}`,
  
  // Position
  getListPositionsEndPoint: '/api/v1/positions',
  getPositionWithIdEndPoint: (params) => `/api/v1/positions/${params.id}`,
  addPositionForInfoEndPoint: '/api/v1/positions',
  updatePositionInfoEndPoint: (params) => `/api/v1/positions/${params.id}`,
  updatePermissionForPositionEndPoint: (params) => `/api/v1/positions/${params.id}/enable-permission-config`,
  deletePositionEndPoint: (params) => `/api/v1/positions/${params.id}`,

  // Products
  getListProductsEndPoint: (params) => `/api/v1/products?page=${params.page}&size=${params.perPage}&order_by=id%20DESC&is_deleted=false`,
  getListProductsVariantsEndPoint: '/api/v1/products/more-detail',
  addProductInfoEndPoint: `/api/v1/products-with-variant`,
  updateProductInfoEndPoint: (params) => `/api/v1/products/${params.id}`,
  getProductEndPoint: (params) => `/api/v1/products/${params.id}`,
  getProductVariantsEndPoint: (params) => `/api/v1/products/${params.id}/more-detail`,
  deleteProductEndPoint: (params) => `/api/v1/products/${params.id}`,
  updateResourceProductEndPoint: (params) => `/api/v1/products/${params.id}/images`,

  // Product Variants
  getListProductVariantEndPoint: (params) => `/api/v1/product-variants?page=${params.page}&size=${params.perPage}&search=${params.q}&is_deleted=false&order_by=id%20DESC`,
  getProductVariantEndPoint: (params) => `/api/v1/product-variants/${params.id}`,
  addProductVariantEndPoint: `/api/v1/product-variants`,
  addExchangeProductVariantEndPoint: (params) => `/api/v1/product-variants/${params.id}/exchange-unit`,
  deleteProductVariantEndPoint: (params) => `/api/v1/product-variants/${params.id}`,
  updateProductVariantEndPoint: (params) => `/api/v1/product-variants/${params.id}`,

  // Discount
  getDiscountsEndPoint: (params) => `/api/v1/discounts?page=${params.page}&size=${params.perPage}&order_by=id%20DESC&is_deleted=false`,
  addDiscountEndPoint: `/api/v1/discounts`,
  editDiscountEndPoint: (params) => `/api/v1/discounts/${params.id}`,
  getDiscountEndPoint: (params) => `/api/v1/discounts/${params.id}`,
  deleteDiscountEndPoint: (params) => `/api/v1/discounts/${params.id}`,

  // Stock
  getListStockEndPoint: (params) => `/api/v1/stocks?page=${params.page}&size=${params.perPage}&order_by=id%20DESC&is_deleted=false&search=${params.q}${(params.branch_id !== undefined ? '&branch_ids=' + params.branch_id : '')}`,
  getStockEndPoint: (params) => `/api/v1/stocks/${params.id}`,

  // Inventory record
  getListInventoryEndPoint: (params) => `/api/v1/inventory-records?page=${params.page}&size=${params.perPage}&branch_id=${params.branch_id}${(params.status !== undefined) ? `&status=${params.status}` : ''}&order_by=id%20DESC&is_deleted=false`,
  getInventoryEndPoint: (params) => `/api/v1/inventory-records/${params.id}`,
  updateInventoryStatusEndPoint: (params) => `/api/v1/inventory-records/${params.id}/status`,
  updateInventoryEndPoint: (params) => `/api/v1/inventory-records/${params.id}`,
  updateInventoryListProductEndPoint: (params) => `/api/v1/inventory-records/${params.id}/product-variant-list`,
  addInventoryEndPoint: `/api/v1/inventory-records`,

  // Payment Method
  getListPaymentMethodsEndPoint: (params) => `/api/v1/payment-methods?page=${params.page}&size=${params.perPage}&order_by=id%20DESC&is_deleted=false`,
  getPaymentMethodEndPoint: (params) => `/api/v1/payment-methods/${params.id}`,
  addPaymentMethodEndPoint: `/api/v1/payment-methods`,
  editPaymentMethodEndPoint: (params) => `/api/v1/payment-methods/${params.id}`,
  deletePaymentMethodEndPoint: (params) => `/api/v1/payment-methods/${params.id}`,

  // Goods Received Note
  addGoodReceivedNoteEndPoint: `/api/v1/goods-received-notes`,
  getListGoodReceivedNoteEndPoint: '/api/v1/goods-received-notes',
  getGoodReceivedNoteEndPoint: (params) => `/api/v1/goods-received-notes/${params.id}`,
  updateGoodReceivedNoteEndPoint: (params) => `/api/v1/goods-received-notes/${params.id}`,
  updateImportGoodReceivedNoteEndPoint: (params) => `/api/v1/goods-received-notes/${params.id}/import?import_date=${params.data.import_date.toISOString()}`,
  updatePaymentGoodReceivedNoteEndPoint: (params) => `/api/v1/goods-received-notes/${params.id}/invoice?invoice_id=${params.data.invoice_id}`,
  deleteGoodReceivedNoteEndPoint: (params) => `/api/v1/goods-received-notes/${params.id}`,

  // Goods Export Note
  getListExportNoteEndPoint: (params) => {
    let link = `/api/v1/goods-export-notes?search=${params.q}&page=${params.page}&size=${params.perPage}&order_by=code%20DESC&branch_ids=${params.branch_id}`
    if (params.status !== undefined) {
      link = params.status?.reduce((prev, curr) => prev + '&status=' + curr.value, link)
    }
    if (params.created_at !== undefined) {
      let arr = params.created_at.toString().split(",")
      link += `&created_at=${arr[0]}&created_at=${arr[1]}`
    }
    return link
  },
  getExportNoteEndPoint: (params) => `/api/v1/goods-export-notes/${params.id}`,
  addExportNoteEndPoint: `/api/v1/goods-export-notes`,
  updateExportNoteEndPoint: (params) => `/api/v1/goods-export-notes/${params.id}`,


  // invoice
  addInvoiceEndPoint: '/api/v1/invoices',
  
  // Resource Products
  getListResourceProductsEndPoint: (params) => `/api/v1/resources?search=${params.q}&page=${params.page}&size=${params.perPage}&order_by=id%20DESC&item_type=product`,
  addResourceProductEndPoint: `/api/v1/resources`,
  deleteResourceProductEndPoint: (params) => `/api/v1/resources/${params.id}`,

  // Tag
  getListTagsEndPoint: (params) => `/api/v1/tags?page=${params.page}&size=${params.perPage}&order_by=id%20DESC`,
  getTagEndPoint: (params) => `/api/v1/tags/${params.id}`,
  addTagEndPoint: `/api/v1/tags`,
  editTagEndPoint: (params) => `/api/v1/tags/${params.id}`,
  deleteTagEndPoint: (params) => `/api/v1/tags/${params.id}`,

  // Type
  getListTypesEndPoint: (params) => `/api/v1/types?page=${params.page}&size=${params.perPage}&order_by=id%20DESC`,
  getTypeEndPoint: (params) => `/api/v1/types/${params.id}`,
  addTypeEndPoint: `/api/v1/types`,
  editTypeEndPoint: (params) => `/api/v1/types/${params.id}`,
  deleteTypeEndPoint: (params) => `/api/v1/types/${params.id}`, 
 
  // Brand
  getListBrandsEndPoint: (params) => `/api/v1/brands?page=${params.page}&size=${params.perPage}&order_by=id%20DESC`,
  getBrandEndPoint: (params) => `/api/v1/brands/${params.id}`,
  addBrandEndPoint: `/api/v1/brands`,
  editBrandEndPoint: (params) => `/api/v1/brands/${params.id}`,
  deleteBrandEndPoint: (params) => `/api/v1/brands/${params.id}`, 

  // Payment request
  getListRequestEndPoint: (params) => `/api/v1/payment-requests?search=${params.search}&page=${params.page}&size=${params.size}&order_by=${params.sortColumn}%20${params.sortDir}`,
  createRequestEndPoint: () => `/api/v1/payment-requests`,
  adminCreateRequestEndPoint: (params) => `/api/v1/payment-requests/${params.id}`,
  getRequestEndPoint: (params) => `/api/v1/payment-requests/${params.id}`,
  updateRequestEndPoint: (params) => `/api/v1/payment-requests/${params.id}/status`,
  // Tax
  getListTaxsEndPoint: (params) => `/api/v1/taxs?page=${params.page}&size=${params.perPage}&order_by=id%20DESC&is_deleted=false`,
  getTaxEndPoint: (params) => `/api/v1/taxs/${params.id}`,
  addTaxEndPoint: `/api/v1/taxs`,
  editTaxEndPoint: (params) => `/api/v1/taxs/${params.id}`,
  deleteTaxEndPoint: (params) => `/api/v1/taxs/${params.id}`, 

   // Property
   getListPropertiesEndPoint: (params) => `/api/v1/properties?page=${params.page}&size=${params.perPage}&order_by=id%20DESC`,
   getPropertyEndPoint: (params) => `/api/v1/properties/${params.id}`,
   addPropertyEndPoint: `/api/v1/properties`,
   editPropertyEndPoint: (params) => `/api/v1/properties/${params.id}`,
   deletePropertyEndPoint: (params) => `/api/v1/properties/${params.id}`, 
   
   // Price Policy
   getListPricePolicyEndPoint: (params) => `/api/v1/price-policies?page=${params.page}&size=${params.perPage}&order_by=id%20DESC`,
   getPricePolicyEndPoint: (params) => `/api/v1/price-policies/${params.id}`,
   addPricePolicyEndPoint: `/api/v1/price-policies`,
   editPricePolicyEndPoint: (params) => `/api/v1/price-policies/${params.id}`,
   deletePricePolicyEndPoint: (params) => `/api/v1/price-policies/${params.id}`,
   
   // Branch
   getListBranchEndPoint: (params) => `/api/v1/branchs?page=${params.page}&size=${params.perPage}&order_by=id%20DESC`,
   getBranchEndPoint: (params) => `/api/v1/branchs/${params.id}`,
   addBranchEndPoint: `/api/v1/branchs`,
   editBranchEndPoint: (params) => `/api/v1/branchs/${params.id}`,
   deleteBranchEndPoint: (params) => `/api/v1/branchs/${params.id}`,

   // Promotions
   getListCartPriceRuleEndPoint: '/api/v1/cart-price-rules',
   createCartPriceRuleEndPoint: '/api/v1/cart-price-rules',
   updateCartPriceRuleEndPoint: '/api/v1/cart-price-rules/{cart_price_rule_id}',
   getCartPriceRuleEndPoint: '/api/v1/cart-price-rules/{cart_price_rule_id}',

}
