import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import useJwt from '@src/auth/jwt/useJwt'
import { closeSwal, handleError, handleLoading, handleQuestion, handleSuccess, toastSuccess } from '../../../sweetalert'

export const getListVariant = createAsyncThunk('importStock/getListVariant', async (params) => {
  try {
    // handleLoading("Vui lòng chờ...")
    params.setIsLoading(true)
    const response = await useJwt.getListProductVariant(params)
    let data = response.data.data
    
    const request = data.map(async(item, index) => {
        const response_2 = await useJwt.getProductVariant({
          id: item.id
        })
        data[index].detail = response_2.data.data
    })
    await Promise.all(request)
    params.setIsLoading(false)
    return {
      params,
      data,
      total: response.data.total_page
    }
  } catch (error) {
    params.setIsLoading(false)
    useJwt.handleError(error)
    return {
      params,
      data,
      total: response.data.total_page
    }
  }
})

export const getListGoodReceivedNotes = createAsyncThunk('importStock/getListGoodReceivedNotes', async params => {
  try {
    params?.setIsLoading(true)
    const response = await useJwt.getListGoodReceivedNotes(params)
    let data = []
    const request =
      response.data.data?.map(async (item, index) => {
        const response_2 = await useJwt.getGoodReceivedNote({
          id: item.id
        })
        data.push({})
        let product_info =  response_2.data.data.product_info
        const request_2 =  product_info?.map(async (item, index) => {
          const response_3 = await useJwt.getProductVariant({
            id: item.product_variants_id
          })
          product_info[index] = {
            ...item,
            ...response_3.data.data
          }
        })
        await Promise.all(request_2)
        data[index] = {
          ...item,
          detail: {
            ...response_2.data.data,
            product_info
          }
        }
      })
    await Promise.all(request)
    params?.setIsLoading(false)
    return {
      data,
      total: response.data.total_page,
      totalRow: response.data.total,
      params
    }
  } catch (error) {
    useJwt.handleError(error)
    return {
      data: {},
      total: 0,
      totalRow: 0,
      params
    }
  }
})

export const addGoodReceivedNote = createAsyncThunk('importStock/addGoodReceivedNote', async (params, {dispatch, getState}) => {
  try {
    params?.setIsLoading(true)
    const response = await useJwt.addGoodReceivedNote(params)

    if (getState().importStock.paramsWApprovalGoodReceivedNotes !== null) {
      await dispatch(
        getListGoodReceivedNotes(getState().importStock.paramsWApprovalGoodReceivedNotes)
      )
    }
    if (getState().importStock.paramsWUnimportedGoodReceivedNotes !== null) {
      await dispatch(
        getListGoodReceivedNotes(getState().importStock.paramsWUnimportedGoodReceivedNotes)
      )
    }
    if (getState().importStock.paramsWUnpaymentGoodReceivedNotes !== null) {
      await dispatch(
        getListGoodReceivedNotes(getState().importStock.paramsWUnpaymentGoodReceivedNotes)
      )
    }

    toastSuccess("Thêm thành công")
    params?.reset()
    params?.setIsLoading(false)
  } catch (error) {
    params?.setIsLoading(false)
    useJwt.handleError(error)
  }
})

export const updateGoodReceivedNote = createAsyncThunk('importStock/updateGoodReceivedNote', async (params, {dispatch, getState}) => {
  try {
    params?.setIsLoading(true)
    const response = await useJwt.updateGoodReceivedNote(params)
    params?.setIsLoading(false)

    if (getState().importStock.paramsWApprovalGoodReceivedNotes !== null) {
      await dispatch(
        getListGoodReceivedNotes(getState().importStock.paramsWApprovalGoodReceivedNotes)
      )
    }
    if (getState().importStock.paramsWUnimportedGoodReceivedNotes !== null) {
      await dispatch(
        getListGoodReceivedNotes(getState().importStock.paramsWUnimportedGoodReceivedNotes)
      )
    }
    if (getState().importStock.paramsWUnpaymentGoodReceivedNotes !== null) {
      await dispatch(
        getListGoodReceivedNotes(getState().importStock.paramsWUnpaymentGoodReceivedNotes)
      )
    }
    if (params.status === "invoice_id") {
      toastSuccess("Đã xác thực")
    } else {
      toastSuccess("Phê duyệt thành công")
    }
  } catch (error) {
    params?.setIsLoading(false)
    useJwt.handleError(error)
    useJwt.handleErrors(error)
  }
})

export const deleteGoodReceivedNote = createAsyncThunk('importStock/deleteGoodReceivedNote', async (params, {dispatch, getState}) => {
  try {
    params?.setIsLoading(true)
    const response = await useJwt.deleteGoodReceivedNote(params)
    params?.setIsLoading(false)
  
    if (getState().importStock.paramsWApprovalGoodReceivedNotes !== null) {
      await dispatch(
        getListGoodReceivedNotes(getState().importStock.paramsWApprovalGoodReceivedNotes)
      )
    }
    if (getState().importStock.paramsWUnimportedGoodReceivedNotes !== null) {
      await dispatch(
        getListGoodReceivedNotes(getState().importStock.paramsWUnimportedGoodReceivedNotes)
      )
    }
    if (getState().importStock.paramsWUnpaymentGoodReceivedNotes !== null) {
      await dispatch(
        getListGoodReceivedNotes(getState().importStock.paramsWUnpaymentGoodReceivedNotes)
      )
    }

    toastSuccess("Xóa thành công")
  } catch (error) {
    params.setIsLoading(false)
    useJwt.handleError(error)
  }
}) 

const importStockSlice = createSlice({
  name: 'importStock',
  initialState: {
    params: null,
    data: [],
    total: 0,
    totalRow: 0,
    goodReceivedNotes: [],
    totalGoodReceivedNotes: 0,
    paramsGoodReceiveNotes: null,

    totalRowWAllGoodReceivedNotes: 0,
    wAllGoodReceivedNotes: [],
    totalWAllGoodReceivedNotes: 0,
    paramsWAllGoodReceivedNotes: null,
    
    totalRowWApprovalGoodReceivedNotes: 0,
    wApprovalGoodReceivedNotes: [],
    totalWApprovalGoodReceivedNotes: 0,
    paramsWApprovalGoodReceivedNotes: null,

    totalRowWUnimportedGoodReceivedNotes: 0,
    wUnimportedGoodReceivedNotes: [],
    totalWUnimportedGoodReceivedNotes: 0,
    paramsWUnimportedGoodReceivedNotes: null,
   
    totalRowWUnpaymentGoodReceivedNotes: 0,
    wUnpaymentGoodReceivedNotes: [],
    totalWUnpaymentGoodReceivedNotes: 0,
    paramsWUnpaymentGoodReceivedNotes: null
  },
  reducers: {
    
  },
  extraReducers: builder => {
    builder.addCase(getListVariant.fulfilled, (state, action) => {
      state.data = action.payload.data
      state.params = action.payload.params
      state.total = action.payload.total
    })
    .addCase(getListGoodReceivedNotes.fulfilled, (state, action) => {
      console.log("🚀 ~ file: index.js:219 ~ .addCase ~ action.payload.params.status:", action.payload.params.status)
      if (action.payload.params.status === "Unapproval") {
        state.totalWApprovalGoodReceivedNotes = action.payload.total
        state.wApprovalGoodReceivedNotes = action.payload.data
        state.totalRowWApprovalGoodReceivedNotes = action.payload.totalRow
        state.paramsWApprovalGoodReceivedNotes = action.payload.params
        return
      }
      if (action.payload.params.status === "Unimported") {
        state.totalWUnimportedGoodReceivedNotes = action.payload.total
        state.wUnimportedGoodReceivedNotes = action.payload.data
        state.totalRowWUnimportedGoodReceivedNotes = action.payload.totalRow
        state.paramsWUnimportedGoodReceivedNotes = action.payload.params
        return
      }
      if (action.payload.params.status === "Unpayment") {
        state.totalWUnpaymentGoodReceivedNotes = action.payload.total
        state.wUnpaymentGoodReceivedNotes = action.payload.data
        state.totalRowWUnpaymentGoodReceivedNotes = action.payload.totalRow
        state.paramsWUnpaymentGoodReceivedNotes = action.payload.params
        return
      }

      
      if (action.payload.params.status) {
        state.totalWAllGoodReceivedNotes = action.payload.total
        state.wAllGoodReceivedNotes = action.payload.data
        state.totalRowWAllGoodReceivedNotes = action.payload.totalRow
        state.paramsWAllGoodReceivedNotes = action.payload.params
        return
      }

    })
  }
})

export default importStockSlice.reducer