// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import useJwt from '@src/auth/jwt/useJwt'

// ** Axios Imports
import axios from 'axios'
import { closeSwal, handleError, handleLoading, toastSuccess } from '../../sweetalert'

export const getData = createAsyncThunk('deviceStatus/getData', async params => {
  try {
    params.setIsLoading(true)
    const response = await useJwt.getListStatusDevices()
    params.setIsLoading(false)
    return { 
      allData: response.data.data, 
      data: response.data.data, 
      totalPages: 1,
      params
    }
    
  } catch (error) {
    useJwt.handleError(error)
    params.setIsLoading(false)
    return {
      allData: [], 
      data: [], 
      totalPages: 1, 
      params 
    }
  }
})

export const getStatusDevice = createAsyncThunk('deviceStatus/getStatusDevice', async params => {
  try {
    params.setBlock(true)
    const response = await useJwt.getStatusDevices(params)
    params.setBlock(false)
    return { 
      statusDeviceChoice: response.data.data, 
    }
  } catch (error) {
    useJwt.handleError(error)
    return {
      statusDeviceChoice: response.data.data
    }
  }
})

export const createStatusDevice = createAsyncThunk('deviceStatus/create', async params => {
  try {
    handleLoading("Vui lòng chờ hệ thống...")
    await useJwt.createStatusDevice(params.data)
    toastSuccess("Tạo thành công")
    if (!params.checkCreate) {
      params.navigateFunction()
    }
    params.reset()
    closeSwal()
  } catch (error) {
    setTimeout(() => {
      closeSwal()
      useJwt.handleError(error)
    }, 1000)
  }
})

export const editStatusDevice = createAsyncThunk('deviceStatus/edit', async params => {
  try {
    handleLoading("Vui lòng chờ hệ thống...")
    const response = await useJwt.editStatusDevice(params.data)
    closeSwal()
    toastSuccess("Chỉnh sửa thành công")
    if (params.checkCreate) {
        params.navigateFunction()
    }
    params.reset()
  } catch (error) {
    closeSwal()
    useJwt.handleError(error)
    params.reset()
  }
})

export const deleteStatusDevice = createAsyncThunk('deviceStatus/delete', async (params, {getState, dispatch}) => {
  try {
    handleLoading("Vui lòng chờ hệ thống...")
    await useJwt.deleteStatusDevice(params)
    const store = getState().deviceStatus
    await dispatch(getData(store.params))
    closeSwal()
    toastSuccess("Xóa thành công")
  } catch (error) {
    closeSwal()
    useJwt.handleError(error)
  }
})

export const deviceStatusSlice = createSlice({
  name: 'deviceStatus',
  initialState: {
    data: [],
    total: 1,
    params: {},
    allData: [],
    statusDeviceChoice: {}
  },
  reducers: {},
  extraReducers: builder => {
    builder.addCase(getData.fulfilled, (state, action) => {
      state.data = action.payload.data
      state.params = action.payload.params
      state.allData = action.payload.allData
      state.total = action.payload.totalPages
    })
    .addCase(getStatusDevice.fulfilled, (state, action) => {
      state.statusDeviceChoice = action.payload.statusDeviceChoice
    })
  }
})

export default deviceStatusSlice.reducer
