// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import useJwt from '@src/auth/jwt/useJwt'
import { closeSwal, handleError, handleLoading, toastSuccess } from '../../sweetalert'

export const getData = createAsyncThunk('pricePolicy/getData', async params => {
  try {
    params.setIsLoading(true)
    const response = await useJwt.getListPricePolicy(params)
    params.setIsLoading(false)
    return { 
      allData: response.data.data, 
      data: response.data.data, 
      totalPages: response.data.total_page, 
      params 
    }
    
  } catch (error) {
    useJwt.handleError(error)
    if (params.setIsLoading !== null && params.setIsLoading !== undefined)
      params.setIsLoading(false)
    return {
      allData: [], 
      data: [], 
      totalPages: 1, 
      params 
    }
  }
})

export const getPricePolicy = createAsyncThunk('pricePolicy/getPricePolicy', async params => {
  try {
    params.setBlock(true)
    const response = await useJwt.getPricePolicy(params)
    params.setBlock(false)
    return { 
      pricePolicyChoice: response.data.data, 
      paramsPricePolicy: params
    }
  } catch (error) {
    useJwt.handleError(error)
    return {
      pricePolicyChoice: {},
      paramsPricePolicy: params
    }
  }
})

export const createPricePolicy = createAsyncThunk('pricePolicy/create', async (params, {dispatch, getState}) => {
  try {
    params.setBlock(true)
    await useJwt.addPricePolicy(params.data)
    params.setBlock(false)
    toastSuccess("Tạo thành công")
    if (!params.checkCreate) {
      params.navigateFunction()
    }
    if (params.isReload !== undefined && params.isReload === true) {
      dispatch(getData(getState().pricePolicy.params))
    }
    params.reset()
  } catch (error) {
    useJwt.handleError(error)
  }
})

export const editPricePolicy = createAsyncThunk('pricePolicy/edit', async (params, {dispatch, getState}) => {
  try {
    params.setBlock(true)
    const response = await useJwt.editPricePolicy(params.data)
    params.setBlock(false)
    toastSuccess("Chỉnh sửa thành công")
    if (params.checkCreate) {
        params.navigateFunction()
    }
    dispatch(
      getPricePolicy(getState().pricePolicy.paramsPricePolicy)
    )
  } catch (error) {
    closeSwal()
    useJwt.handleError(error)
    params.reset()
  }
})

export const deletePricePolicy = createAsyncThunk('pricePolicy/delete', async (params, {getState, dispatch}) => {
  try {
    handleLoading("Vui lòng chờ hệ thống...")
    await useJwt.deletePricePolicy(params)
    const store = getState().pricePolicy
    await dispatch(getData(store.params))
    closeSwal()
    toastSuccess("Xóa thành công")
  } catch (error) {
    closeSwal()
    useJwt.handleError(error)
  }
})

export const pricePolicySlice = createSlice({
  name: 'pricePolicy',
  initialState: {
    data: [],
    total: 1,
    params: {},
    allData: [],
    pricePolicyChoice: {},
    paramsPricePolicy: {}
  },
  reducers: {},
  extraReducers: builder => {
    builder.addCase(getData.fulfilled, (state, action) => {
      state.data = action.payload.data
      state.params = action.payload.params
      state.allData = action.payload.allData
      state.total = action.payload.totalPages
    })
    .addCase(getPricePolicy.fulfilled, (state, action) => {
      state.pricePolicyChoice = action.payload.pricePolicyChoice
      state.paramsPricePolicy = action.payload.paramsPricePolicy
    })
  }
})

export default pricePolicySlice.reducer
