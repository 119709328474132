// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import useJwt from '@src/auth/jwt/useJwt'

// ** Axios Imports
import axios from 'axios'
import { closeSwal, handleError, handleLoading, toastSuccess } from '../../sweetalert'

export const getData = createAsyncThunk('suppliersDevice/getData', async params => {
  try {
    params.setIsLoading(true)
    const response = await useJwt.getListSuppliers(params)
    params.setIsLoading(false)
    console.log(response)
    return { 
      allData: response.data.data, 
      data: response.data.data, 
      totalPages: response.data.total_page, 
      supplierChoice: {},
      params 
    }
    
  } catch (error) {
    useJwt.handleError(error)
    params.setIsLoading(false)
    console.log("reset")
    return {
      allData: [], 
      data: [], 
      totalPages: 1, 
      params 
    }
  }
})

export const getSupplier = createAsyncThunk('suppliersDevice/getSupplier', async params => {
  try {
    params.setBlock(true)
    const response = await useJwt.getSupplier(params)
    // console.log(response)
    params.setBlock(false)
    return { 
      supplierChoice: response.data.data, 
    }
  } catch (error) {
    useJwt.handleError(error)
  }
})

export const createSupplier = createAsyncThunk('suppliersDevice/create', async params => {
  try {
    handleLoading("Vui lòng chờ hệ thống...")
    console.log("params", params)
    await useJwt.createSupplier(params.data)
    toastSuccess("Tạo thành công")
    if (!params.checkCreate) {
      params.navigateFunction()
    }
    params.reset()
    closeSwal()
  } catch (error) {
    setTimeout(() => {
      closeSwal()
      useJwt.handleError(error)
    }, 1000)
  }
})

export const editSupplier = createAsyncThunk('suppliersDevice/edit', async params => {
  try {
    handleLoading("Vui lòng chờ hệ thống...")
    const response = await useJwt.editSupplier(params.data)
    closeSwal()
    toastSuccess("Chỉnh sửa thành công")
    if (params.checkCreate) {
        params.navigateFunction()
    }
    return {
      supplierChoice: response.data.data, 
    }
  } catch (error) {
    closeSwal()
    useJwt.handleError(error)
    params.reset()
    return {
      supplierChoice: null
    }
  }
})

export const deleteSupplier = createAsyncThunk('suppliersDevice/delete', async (params, {getState, dispatch}) => {
  try {
    console.log("store", params)
    handleLoading("Vui lòng chờ hệ thống...")
    await useJwt.deleteSupplier(params)
    const store = getState().suppliersDevice
    await dispatch(getData(store.params))
    closeSwal()
    toastSuccess("Xóa thành công")
  } catch (error) {
    closeSwal()
    useJwt.handleError(error)
  }
})

export const suppliersSlice = createSlice({
  name: 'suppliersDevice',
  initialState: {
    data: [],
    total: 1,
    params: {},
    allData: [],
    supplierChoice: {}
  },
  reducers: {},
  extraReducers: builder => {
    builder.addCase(getData.fulfilled, (state, action) => {
      state.data = action.payload.data
      state.params = action.payload.params
      state.allData = action.payload.allData
      state.total = action.payload.totalPages
    })
    .addCase(getSupplier.fulfilled, (state, action) => {
      console.log(action.payload.supplierChoice)
      state.supplierChoice = {
        ...action.payload.supplierChoice,
        supplier_type: {
          label: 'Sản phẩm',
          value: action.payload.supplierChoice.supplier_type
        },
        status: {
          label: action.payload.supplierChoice.status,
          value: (action.payload.supplierChoice.status === "Đang giao dịch") ? "working" : 'stopped'
        }
      }
    })
    .addCase(editSupplier.fulfilled, (state, action) => {
      if (action.payload.supplierChoice !== null) {
        state.supplierChoice = {
          ...action.payload.supplierChoice,
          supplier_type: {
            label: 'Sản phẩm',
            value: action.payload.supplierChoice.supplier_type
          },
          status: {
            label: action.payload.supplierChoice.status,
            value: (action.payload.supplierChoice.status === "Đang giao dịch") ? "working" : 'stopped'
          }
        }
      }
    })
  }
})

export default suppliersSlice.reducer
