import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import useJwt from '@src/auth/jwt/useJwt'

export const getAllData = createAsyncThunk('deviceHistory/getAllData', async params => {
  const response = await useJwt.getAllDeviceHistory(params)
  return response.data
})

export const getData = createAsyncThunk('deviceHistory/getData', async params => {
  try {
    const response = await useJwt.getDeviceHistory(params)
    console.log(params)
    return {
      params,
      data: response.data,
      totalPages: response.total
    }
  } catch (error) {
    console.log(error)
    useJwt.handleError(error)
    return {
      allData: [], 
      data: [], 
      totalPages: 1, 
      params 
    }
  }
})

export const addHistory = createAsyncThunk('deviceHistory/addHistory', async (history, {dispatch, getState}) => {
  try {
    console.log(history)
    await useJwt.addDeviceHistory(history)
    await dispatch(getData({
      sortColumn: "id",
      sortDir: "desc".toUpperCase(),
      search: "",
      page: 1,
      size: 10,
      is_deleted: false,
      id: history.device_id
    }
    ))
    // await dispatch(getAllData())
    return history
  } catch (error) {
    console.log(error)
    useJwt.handleError(error)
    return {
      history
    }
  }
})
export const getHistory = createAsyncThunk('deviceHistory/getHistory', async id => {
  try {
    const response = await useJwt.getOneDeviceHistory({id: id})
    return response.data.data
  } catch (error) {
    useJwt.handleError(error)
  }
})
export const deviceHistorySlice = createSlice({
  name: 'deviceHistory',
  initialState: {
    data: [],
    total: 1,
    params: {},
    allData: [],
    selected: null
  },
  reducers: {},
  extraReducers: builder => {
    builder
    .addCase(getAllData.fulfilled, (state, action) => {
      state.allData = action.payload
    })
    .addCase(getData.fulfilled, (state, action) => {
      state.data = action.payload.data
      state.params = action.payload.params
      state.total = action.payload.totalPages
    })
    .addCase(getHistory.fulfilled, (state, action) => {
      console.log(action.payload)
      state.selected = action.payload
    })
  }
})

export default deviceHistorySlice.reducer