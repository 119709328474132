// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import useJwt from '@src/auth/jwt/useJwt'

// ** Axios Imports
import axios from 'axios'
import { closeSwal, handleLoading, toastSuccess } from '../../../pages/foster/sweetalert'

export const getAllData = createAsyncThunk('appUsers/getAllData', async () => {
  const response = await axios.get('/api/users/list/all-data')
  return response.data
})

export const getData = createAsyncThunk('appUsers/getData', async params => {
 try {
    params.setIsShowLoading(true)
    const response = await useJwt.getListUsers(params)
    const getRole = async (row) => {
      if (row.role_id !== null && row.role_id !== undefined) {
        const response = await useJwt.getRole({
          id: row.role_id
        })
        return {
          data: response.data.data
        }
      } else {
        return {
          data: {
            name: 'Chưa có'
          }
        }
      }
    }
    const users = response.data.data

    const users2 = []
    const promises = users.map(async item => {
      const role = await getRole(item)
      users2.push(
        {
          ...item,
          rolen_name: role.data.name
        }
      )
    })

    await Promise.all(promises)
    params.setIsShowLoading(false)
    return {
      params,
      data: users2,
      totalPages: response.data.total_page
    }
  } catch (error) {
    useJwt.handleError(error)
    return {
      params,
      data: [],
      totalPages: 1
    }
  }
})


export const getUser = createAsyncThunk('appUsers/getUser', async params => {
  try {
    params.setIsLoading(true)
    const response = await useJwt.getUser(params)
    params.setIsLoading(false)
    return {
      user: response.data.data,
      params
    }
  } catch (error) {
    params.setIsLoading(false)
    useJwt.handleError(error)
    return {
      user: null
    }
  }
})

export const addUser = createAsyncThunk('appUsers/addUser', async (params, { dispatch, getState }) => {
  try {
    const response = await useJwt.addUsers(params)
    await dispatch(getData(getState().users.params))
    // await dispatch(getAllData())
    return user
  } catch (error) {
    useJwt.handleError(error)
  }
})

export const editUser = createAsyncThunk("appUsers/editUser", async (params, {dispatch, getState}) => {
  try {
    params.setIsLoading(true)
    const response = await useJwt.updateInfoUser(params)
    dispatch(getUser(getState().users.paramsSelected))
    params.setIsLoading(false)
    toastSuccess(response.data.detail)
  } catch (error) {
    params.setIsLoading(false)
    useJwt.handleError(error)
  }
})

export const deleteUser = createAsyncThunk('appUsers/deleteUser', async (params, { dispatch, getState }) => {
  try {
    handleLoading("Vui lòng chờ hệ thống...")
    await useJwt.deleteUser(params)
    dispatch(getData(getState().users.params))
    closeSwal()
  } catch (error) {
    closeSwal()
    useJwt.handleError(error)
  }
})

export const appUsersSlice = createSlice({
  name: 'appUsers',
  initialState: {
    data: [],
    total: 1,
    params: {},
    allData: [],
    selectedUser: null,
    paramsSelected: null,
    dataToAddNew: {},
    department: []
  },
  reducers: {
    setDataToAddNew: (state, action) => {
      return {
        ...state,
        dataToAddNew: {
          ...state.dataToAddNew,
          ...action.payload
        }
      }
    }
  },
  extraReducers: builder => {
    builder
      .addCase(getAllData.fulfilled, (state, action) => {
        state.allData = action.payload
      })
      .addCase(getData.fulfilled, (state, action) => {
        state.data = action.payload.data
        state.params = action.payload.params
        state.total = action.payload.totalPages
      })
      .addCase(getUser.fulfilled, (state, action) => {
        state.selectedUser = action.payload.user
        state.paramsSelected = action.payload.params
      })
  }
})

export default appUsersSlice.reducer
export const {setDataToAddNew} = appUsersSlice.actions
