import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import useJwt from '@src/auth/jwt/useJwt'

export const getData = createAsyncThunk('permissionMatrix/getData', async (params) => {
  try {
    const response = await useJwt.getPermissionMatrix()
    return {
      data: response.data.data
    }
  } catch (error) {
    useJwt.handleError(error)
    return {
      data: []
    }
  }
})
export const matrixSlice = createSlice({
  name: 'permissionMatrix',
  initialState: {
    data: [],
  },
  reducers: {},
  extraReducers: builder => 
    builder.addCase(getData.fulfilled, (state, action) => {
      state.data = action.payload.data
    })
})

export default matrixSlice.reducer