// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import useJwt from '@src/auth/jwt/useJwt'
import { closeSwal, handleError, handleLoading, toastSuccess } from '../../sweetalert'

export const getData = createAsyncThunk('taxs/getData', async params => {
  try {
    params.setIsLoading(true)
    const response = await useJwt.getListTaxs(params)
    params.setIsLoading(false)
    return {
      allData: response.data.data, 
      data: response.data.data, 
      totalPages: response.data.total_page, 
      params 
    }
    
  } catch (error) {
    useJwt.handleError(error)
    if (params.setIsLoading !== null && params.setIsLoading !== undefined)
      params.setIsLoading(false)
    return {
      allData: [], 
      data: [], 
      totalPages: 1, 
      params 
    }
  }
})

export const getTax = createAsyncThunk('taxs/getTax', async params => {
  try {
    params.setBlock(true)
    const response = await useJwt.getTax(params)
    params.setBlock(false)
    return { 
      taxChoice: response.data.data, 
      paramsTax: params
    }
  } catch (error) {
    useJwt.handleError(error)
    return {
      taxChoice: {},
      paramsTax: params
    }
  }
})

export const createTax = createAsyncThunk('taxs/create', async (params, {dispatch, getState}) => {
  try {
    params.setBlock(true)
    await useJwt.addTax(params.data)
    params.setBlock(false)
    toastSuccess("Tạo thành công")
    if (!params.checkCreate) {
      params.navigateFunction()
    }
    dispatch(getData(getState().taxs.params))
    params.reset()
  } catch (error) {
    useJwt.handleError(error)
  }
})

export const editTax = createAsyncThunk('taxs/edit', async (params, {dispatch, getState}) => {
  try {
    params.setBlock(true)
    const response = await useJwt.editTax(params.data)
    params.setBlock(false)
    toastSuccess("Chỉnh sửa thành công")
    if (params.checkCreate) {
        params.navigateFunction()
    }
    dispatch(
      getTax(getState().taxs.paramsTax)
    )
  } catch (error) {
    closeSwal()
    useJwt.handleError(error)
    params.reset()
  }
})

export const deleteTax = createAsyncThunk('taxs/delete', async (params, {getState, dispatch}) => {
  try {
    handleLoading("Vui lòng chờ hệ thống...")
    await useJwt.deleteTax(params)
    const store = getState().taxs
    await dispatch(getData(store.params))
    closeSwal()
    toastSuccess("Xóa thành công")
  } catch (error) {
    closeSwal()
    useJwt.handleError(error)
  }
})

export const taxSlice = createSlice({
  name: 'taxs',
  initialState: {
    data: [],
    total: 1,
    params: {},
    allData: [],
    taxChoice: {},
    paramsTax: {}
  },
  reducers: {},
  extraReducers: builder => {
    builder.addCase(getData.fulfilled, (state, action) => {
      state.data = action.payload.data
      state.params = action.payload.params
      state.allData = action.payload.allData
      state.total = action.payload.totalPages
    })
    .addCase(getTax.fulfilled, (state, action) => {
      state.taxChoice = action.payload.taxChoice
      state.paramsTax = action.payload.paramsTax
    })
  }
})

export default taxSlice.reducer
