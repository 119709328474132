// ** React Imports
import { createContext, useEffect, useState } from 'react'

import { Link, useNavigate } from 'react-router-dom'

// ** UseJWT import to get config
import useJwt from '@src/auth/jwt/useJwt'

const config = useJwt.jwtConfig

import toast from 'react-hot-toast'
import { AxiosError } from 'axios'

// ** Defaults
const defaultProvider = {
  user: null,
  loading: true,
  setUser: () => null,
  setLoading: () => null,
  permissions: [],

  login: () => Promise.resolve(),

  logout: () => Promise.resolve()

  // register: () => Promise.resolve()
}

const AuthContext = createContext(defaultProvider)

const AuthProvider = ({ children }) => {
  
  const navigate = useNavigate()

  // ** States
  const [user, setUser] = useState(defaultProvider.user)
  const [permissions, setPermissions] = useState(null)
  const [loading, setLoading] = useState(false)

  const loginMutate = async (params) => {
    try {
      const response = await useJwt.login(params)
      window.localStorage.setItem(config.storageTokenKeyName, response.data.data.access_token)
      window.localStorage.setItem(config.storageRefreshTokenKeyName, response.data.data.refresh_token)

      return response
    } catch (error) {
      if (error instanceof AxiosError) {
        toast.error(error.response?.data.message)
      }
      console.log('🚀 ~ file: AuthContext.tsx:72 ~ onError ~ error:', error)
    }
  }

  const getMe = async () => {
    try {
      const response = await useJwt.getMe()
  
      setLoading(false)
  
      const userData = {
        id: response.data.data.id,
        fullName: response.data.data.full_name,
        username: response.data.data.email,
        password: '',
        email: response.data.data.email,
        role: response.data.data.role_name,
        role_id: response.data.data.role_id,
        department_name: response.data.data.department_name,
        department_code: response.data.data.department_code,
        unit_name: response.data.data.unit_name,
        unit_code: response.data.data.unit_code,
        ability: [
          {
            action: 'manage',
            subject: 'all'
          }
        ],
        extras: {
          eCommerceCartItemsCount: 5
        },
        ...response.data.data,
        avatar: (response.data.data.avatar_url !== "") ? response.data.data.avatar_url : require('@src/assets/images/portrait/small/avatar-s-11.jpg').default,
      }

      localStorage.setItem(config.storageUserDataKeyName, JSON.stringify(userData))
      
      setUser(userData)
      setPermissions(userData.permissions || null)
    } catch(e) {
      console.log('🚀 ~ file: AuthContext.tsx:89 ~ onError ~ e:', e)

      handleLogout()

      setUser(null)
      setLoading(false)

      // router.replace('/login')
    }
  }

  const refreshToken = () => {
    return useJwt.refreshToken()
  }

  useEffect(() => {
    const initAuth = async () => {
      try {
        setLoading(true)
        const _r = await refreshToken();
        const _m = await getMe()
        setLoading(false)
      }
      catch(error) {
        handleLogout()
      }
    }
    initAuth()
  }, [])

  const handleLogin = async (params) => {
    const loginData = await loginMutate({
      username: params.email,
      password: params.password
    })

    await getMe()

    return loginData
  }

  const handleLogout = () => {
    window.localStorage.removeItem(config.storageUserDataKeyName)
    window.localStorage.removeItem(config.storageTokenKeyName)
    window.localStorage.removeItem(config.storageRefreshTokenKeyName)
    navigate('/login')
  }

  const values = {
    user,
    loading,
    permissions,
    setLoading,
    setUser,

    login: handleLogin,
    logout: handleLogout
  }

  return <AuthContext.Provider value={values}>{children}</AuthContext.Provider>
}

export { AuthContext, AuthProvider }
