// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import useJwt from '@src/auth/jwt/useJwt'
import { closeSwal, handleError, handleLoading, toastSuccess } from '../../sweetalert'

export const getData = createAsyncThunk('properties/getData', async params => {
  try {
    params.setIsLoading(true)
    const response = await useJwt.getListProperties(params)
    params.setIsLoading(false)
    return { 
      allData: response.data.data, 
      data: response.data.data, 
      totalPages: response.data.total_page, 
      params 
    }
    
  } catch (error) {
    useJwt.handleError(error)
    if (params.setIsLoading !== null && params.setIsLoading !== undefined)
      params.setIsLoading(false)
    return {
      allData: [], 
      data: [], 
      totalPages: 1, 
      params 
    }
  }
})

export const getProperty = createAsyncThunk('properties/getProperty', async params => {
  try {
    params.setBlock(true)
    const response = await useJwt.getProperty(params)
    params.setBlock(false)
    return { 
      propertyChoice: response.data.data, 
      paramsProperty: params
    }
  } catch (error) {
    useJwt.handleError(error)
    return {
      propertyChoice: {},
      paramsProperty: params
    }
  }
})

export const createProperty = createAsyncThunk('properties/create', async (params, {dispatch, getState}) => {
  try {
    params.setBlock(true)
    await useJwt.addProperty(params.data)
    params.setBlock(false)
    toastSuccess("Tạo thành công")
    if (!params.checkCreate) {
      params.navigateFunction()
    }
    if (params.isReload !== undefined && params.isReload === true) {
      dispatch(
        getData(
          getState().properties.params
        )
      )
    }
    params.reset()
  } catch (error) {
    useJwt.handleError(error)
  }
})

export const editProperty = createAsyncThunk('properties/edit', async (params, {dispatch, getState}) => {
  try {
    params.setBlock(true)
    const response = await useJwt.editProperty(params.data)
    params.setBlock(false)
    toastSuccess("Chỉnh sửa thành công")
    if (params.checkCreate) {
        params.navigateFunction()
    }
    dispatch(
      getProperty(getState().properties.paramsProperty)
    )
  } catch (error) {
    closeSwal()
    useJwt.handleError(error)
    params.reset()
  }
})

export const deleteProperty = createAsyncThunk('properties/delete', async (params, {getState, dispatch}) => {
  try {
    handleLoading("Vui lòng chờ hệ thống...")
    await useJwt.deleteProperty(params)
    const store = getState().properties
    await dispatch(getData(store.params))
    closeSwal()
    toastSuccess("Xóa thành công")
  } catch (error) {
    closeSwal()
    useJwt.handleError(error)
  }
})

export const propertySlice = createSlice({
  name: 'properties',
  initialState: {
    data: [],
    total: 1,
    params: {},
    allData: [],
    propertyChoice: {},
    paramsProperty: {}
  },
  reducers: {},
  extraReducers: builder => {
    builder.addCase(getData.fulfilled, (state, action) => {
      state.data = action.payload.data
      state.params = action.payload.params
      state.allData = action.payload.allData
      state.total = action.payload.totalPages
    })
    .addCase(getProperty.fulfilled, (state, action) => {
      state.propertyChoice = action.payload.propertyChoice
      state.paramsProperty = action.payload.paramsProperty
    })
  }
})

export default propertySlice.reducer
