// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import useJwt from '@src/auth/jwt/useJwt'

// ** Axios Imports
import axios from 'axios'
import toast from 'react-hot-toast'

export const getAllData = createAsyncThunk('devices/getAllData', async () => {
  const response = await axios.get('/api/v1/devices?is_deleted=false')
  return response.data
})

export const getData = createAsyncThunk('devices/getData', async params => {
  try {
    const response = await useJwt.getListDevices(params)
    console.log(response)
    // let respon = {...response.data}
    // let data = []
    // await response.data.data.map(async (d, index) => {
    //   const res = await useJwt.getSupplier({id: d.supplier_id})
    //   data = [...data, {...d, supplier_name: res.data.data.name}]
    //   if (index === response.data.data.length - 1) {
    //     respon = {...respon, data: data}
    //   }
    // })
    return {
      params,
      data: response.data,
      totalPages: response.total
    }
  } catch (error) {
    console.log(error)
    useJwt.handleError(error)
    return {
      allData: [], 
      data: [], 
      totalPages: 1, 
      params 
    }
  }
})

export const getDevice = createAsyncThunk('devices/getDevice', async id => {
  console.log(id)
  try {
    const response = await useJwt.getDevice({id: id})
    console.log(response)
    return response.data.data
  } catch (error) {
    useJwt.handleError(error)
  }
})

export const addDevice = createAsyncThunk('devices/addDevice', async (device, { dispatch, getState }) => {
  try{
    await axios.post('/api/v1/devices', device)
    toast.success('Thêm thành công')
    await dispatch(getData(getState().device.params))
    await dispatch(getAllData())

  } catch( e) {
    console.log(e)
    toast.error('Lỗi hệ thống');
  }
  return device
})

export const deleteDevice = createAsyncThunk('devices/deleteDevice', async (id, { dispatch, getState }) => {
  await axios.delete(`/api/v1/devices/${ id }`, )
  await dispatch(getData(getState().id.params))
  await dispatch(getAllData())
  return id
})

export const updateDevice = createAsyncThunk('devices/updateDevice', async (params, {dispatch, getState}) => {
  await axios.patch(`/api/v1/devices/${params.id}`, params.data)
  await dispatch(getDevice(params.id))
  return params.data
})

export const deviceSlice = createSlice({
  name: 'device',
  initialState: {
    data: [],
    total: 1,
    params: {},
    allData: [],
    selectedDevice: null
  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getAllData.fulfilled, (state, action) => {
        state.allData = action.payload
      })
      .addCase(getData.fulfilled, (state, action) => {
        // console.log(action.payload.data)
        state.data = action.payload.data
        state.params = action.payload.params
        state.total = action.payload.totalPages
      })
      .addCase(getDevice.fulfilled, (state, action) => {
        console.log(action.payload)
        state.selectedDevice = action.payload
      })
  }
})

export default deviceSlice.reducer
