// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import useJwt from '@src/auth/jwt/useJwt'

// ** Axios Imports
import axios from 'axios'
import { closeSwal, handleError, handleLoading, toastSuccess } from '../../sweetalert'

export const getData = createAsyncThunk('roles/getData', async params => {
  try {
    params.setIsLoading(true)
    const response = await useJwt.getRoles(params)
    params.setIsLoading(false)
    return { 
      allData: response.data.data, 
      data: response.data.data, 
      totalPages: response.data.total_page, 
      params 
    }
    
  } catch (error) {
    useJwt.handleError(error)
    params.setIsLoading(false)
    return {
      allData: [], 
      data: [], 
      totalPages: 1, 
      params 
    }
  }
})

export const getPermissionMatrix = createAsyncThunk('roles/getPermissionMatrix', async (params, {dispatch}) => {
  try {
    params.setShowLoading(true)
    const response = await useJwt.getPermissionMatrix()
    if (params.id !== undefined) {
      dispatch(
        getRoleWithIDAdmin({
          id: params.id
        })
      )
    }
    params.setShowLoading(false)
    return {
      status: true,
      permissionMatrix: response.data.data
    }
  } catch (error) {
    useJwt.handleError(error)
    return {
      status: false
    }
  }
})

export const getStatusDevice = createAsyncThunk('roles/getStatusDevice', async params => {
  try {
    params.setBlock(true)
    const response = await useJwt.getStatusDevices(params)
    params.setBlock(false)
    return { 
      statusDeviceChoice: response.data.data, 
    }
  } catch (error) {
    useJwt.handleError(error)
    return {
      statusDeviceChoice: response.data.data
    }
  }
})

export const createNewRole = createAsyncThunk('roles/create', async params => {
  try {
    // console.log(params.data)
    // handleLoading("Vui lòng chờ hệ thống...")
    params.setShowLoading(true)
    const response = await useJwt.createNewRole(params.data)
    await useJwt.updatePermission({
      ...params.data,
      id: response.data.data.id
    })
    toastSuccess("Tạo thành công")
    if (!params.checkCreate) {
      params.navigateFunction()
    }
    params.setShowLoading(false)
    params.reset()
    closeSwal()
  } catch (error) {
    setTimeout(() => {
      closeSwal()
      useJwt.handleError(error)
    }, 1000)
  }
})

export const editRole = createAsyncThunk('roles/edit', async (params, {dispatch}) => {
  try {
    params.setShowLoading(true)
    const response = await useJwt.updateRole(params.data)
    await useJwt.updatePermission({
      ...params.data,
    })

    toastSuccess("Chỉnh sửa thành công")

    if (!params.checkCreate) {
      params.navigateFunction()
    } else {
      params.setShowLoading(false)
      params.navigate(`/apps/user/edit-role/${params.data.id}/${params.data.name}`)
    }
  } catch (error) {
    params.setShowLoading(false)
    closeSwal()
    useJwt.handleError(error)
  }
})

export const getRoleWithID = createAsyncThunk('roles/getDataWithID', async params => {
  try {
    const response = await useJwt.getRole(params)
    return {
      roleChoice: response.data.data
    }
  } catch (error) {
    closeSwal()
    useJwt.handleError(error)
    return {
      roleChoice: {}
    }
  }
})

export const getRoleWithIDAdmin = createAsyncThunk('roles/getDataWithIDAdmin', async params => {
  try {
    const response = await useJwt.getRoleAdmin(params)
    return {
      roleChoice: response.data.data
    }
  } catch (error) {
    closeSwal()
    useJwt.handleError(error)
    return {
      roleChoice: []
    }
  }
})

export const deleteRole = createAsyncThunk('roles/delete', async (params, {getState, dispatch}) => {
  try {
    handleLoading("Vui lòng chờ hệ thống...")
    await useJwt.deleteRole(params)
    const store = getState().roles
    await dispatch(getData(store.params))
    closeSwal()
    toastSuccess("Xóa thành công")
  } catch (error) {
    closeSwal()
    useJwt.handleError(error)
  }
})

export const rolesSlice = createSlice({
  name: 'roles',
  initialState: {
    data: [],
    total: 1,
    params: {},
    allData: [],
    permissionMatrix: [],
    statusDeviceChoice: {},
    roleChoice: []
  },
  reducers: {},
  extraReducers: builder => {
    builder.addCase(getData.fulfilled, (state, action) => {
      state.data = action.payload.data
      state.params = action.payload.params
      state.allData = action.payload.allData
      state.total = action.payload.totalPages
    })
    .addCase(getStatusDevice.fulfilled, (state, action) => {
      state.statusDeviceChoice = action.payload.statusDeviceChoice
    })
    .addCase(getPermissionMatrix.fulfilled, (state, action) => {
      state.permissionMatrix = action.payload.permissionMatrix
    })
    .addCase(getRoleWithID.fulfilled, (state, action) => {
      state.roleChoice = action.payload.roleChoice
    })
    .addCase(getRoleWithIDAdmin.fulfilled, (state, action) => {
      state.roleChoice = action.payload.roleChoice
    })
  }
})

export default rolesSlice.reducer
