import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import useJwt from '@src/auth/jwt/useJwt'
import { closeSwal, handleLoading, toastSuccess } from "../../../sweetalert";

export const getData = createAsyncThunk('inventoryStock/getData', async (params, {dispatch, getState}) => {
  try {

    if (params.setIsLoading !== undefined) {
      params.setIsLoading(true)
    }

    const response = await useJwt.getListInventoryStock(params)
    let data = []
    const request = response.data.data?.map( async item => {
      const response_2 = await useJwt.getInventory({
        id: item.id
      })
      data.push({
        ...response_2.data.data
      })
    })
    await Promise.all(request)

    // dispatch(
    //   getData(getState.inventoryStock)
    // )

    if (params.setIsLoading !== undefined) {
      params.setIsLoading(false)
    }

    return {
      data,
      total: response.data.total_page,
      totalRow: response.data.total,
      params
    }

  } catch (error) {
    if (params.setIsLoading !== undefined) {
      params.setIsLoading(false)
    }
    useJwt.handleError(error)

    return {
      data: [],
      total: 0,
      totalRow: 0,
      params
    }
  }
})

export const getListVariant = createAsyncThunk('inventoryStock/getListVariant', async (params) => {
  try {
    // handleLoading("Vui lòng chờ...")
    if (params.setIsLoading !== undefined) {
      params.setIsLoading(true)
    }
    const response = await useJwt.getListProductVariant(params)
    let data = response.data.data
    
    const request = data.map(async(item, index) => {
        const response_2 = await useJwt.getProductVariant({
          id: item.id
        })
        data[index].detail = response_2.data.data
    })
    await Promise.all(request)
    if (params.setIsLoading !== undefined) {
      params.setIsLoading(false)
    }
    return {
      params,
      data,
    }
  } catch (error) {
    if (params.setIsLoading !== undefined) {
      params.setIsLoading(false)
    }
    useJwt.handleError(error)
    return {
      params,
      data,
    }
  }
})

export const addInventory = createAsyncThunk('inventoryStock/addInventoryStock',async (params) => {
  try {
    handleLoading("Vui lòng chờ hệ thống")
    const response = await useJwt.addInventoryStock(params)
    toastSuccess("Tạo thành công")
    closeSwal()
    return true
  } catch (error) {
    closeSwal()
    useJwt.handleError(error)
    return false
  }
})

export const updateInventoryStatus = createAsyncThunk('inventoryStock/updateInventoryStockStatus', async (params, {getState, dispatch}) => {
  try {
    if (params.setIsLoading !== undefined) {
      params.setIsLoading(true)
    }
    const response = await useJwt.updateInventoryStatus(params)
    toastSuccess("Cập nhật thành công")
    if (getState().inventoryStock.params !== null) {
      await dispatch(
        getData(
          getState().inventoryStock.params
        )
      )
    }
    if (getState().inventoryStock.paramsWaiting !== null) {
      await dispatch(
        getData(
          getState().inventoryStock.paramsWaiting
        )
      )
    }
    if (params.setIsLoading !== undefined) {
      params.setIsLoading(false)
    }
  } catch (error) {
    if (params.setIsLoading !== undefined) {
      params.setIsLoading(false)
    }
    useJwt.handleError(error)
  }
})

export const updateInventory = createAsyncThunk('inventoryStock/updateInventoryStockInfo', async (params) => {
  try {
    const response = await useJwt.updateInventoryInfo(params)
    return true
  } catch (error) {
    useJwt.handleError(error)
    return false
  }
})


export const updateInventoryListProduct = createAsyncThunk('inventoryStock/updateInventoryStockListProduct', async (params) => {
  try {
    const response = await useJwt.updateInventoryListProduct(params)
    return true
  } catch (error) {
    useJwt.handleError(error)
    return false
  }
})

const inventoryStockSlice = createSlice({
  name: 'inventoryStock',
  initialState: {
    data: [],
    total: 0,
    totalRow: 0,
    products: [],
    
    params: null,
    paramsWaiting: null,
    dataWaiting: [],
    totalWaiting: 0,
    totalRowWaiting: 0
  },
  reducers: {

  },
  extraReducers: builder => 
    builder.addCase(getData.fulfilled, (state, action) => {
      console.log(action.payload.params)
      if (action.payload.params?.tab === '1') {
        state.data = action.payload.data
        state.total = action.payload.total
        state.totalRow = action.payload.totalRow
        state.params = action.payload.params
      } else if (action.payload.params?.tab === '3') {
        state.dataWaiting = action.payload.data
        state.totalWaiting = action.payload.total
        state.totalRowWaiting = action.payload.totalRow
        state.paramsWaiting = action.payload.params
      }
    })
    .addCase(getListVariant.fulfilled, (state, action) => {
      state.products = action.payload.data
    })
})

export default inventoryStockSlice.reducer