import axios from "axios";
import { toastWarning } from "../../../views/pages/foster/sweetalert";
import jwtDefaultConfig from "./jwtDefaultConfig";

export default class JwtService {
  // ** jwtConfig <= Will be used by this service
  jwtConfig = { ...jwtDefaultConfig };

  // ** For Refreshing Token
  isAlreadyFetchingAccessToken = false;

  // ** For Refreshing Token
  subscribers = [];

  constructor(jwtOverrideConfig) {
    this.jwtConfig = { ...this.jwtConfig, ...jwtOverrideConfig };

    // ** Request Interceptor
    axios.interceptors.request.use(
      (config) => {
        // ** Get token from localStorage
        const accessToken = this.getToken();

        // ** If token is present add it to request's Authorization Header
        config.headers.language = "vi";
        config.headers.platform = "web";
        if (accessToken) {
          // ** eslint-disable-next-line no-param-reassign
          config.headers.Authorization = `${this.jwtConfig.tokenType} ${accessToken}`;
        }
        config.baseURL = this.jwtConfig.endPoint;
        return config;
      },
      (error) => Promise.reject(error)
    );

    // ** Add request/response interceptor
    axios.interceptors.response.use(
      (response) => response,
      (error) => {
        // ** const { config, response: { status } } = error
        const { config, response } = error;
        const originalRequest = config;

        // ** if (status === 401) {
        if (response && response.status === 401) {
          return Promise.reject(error);
          if (!this.isAlreadyFetchingAccessToken) {
            this.isAlreadyFetchingAccessToken = true;
            this.refreshToken().then((r) => {
              this.isAlreadyFetchingAccessToken = false;

              // ** Update accessToken in localStorage
              this.setToken(r.data.accessToken);
              this.setRefreshToken(r.data.refreshToken);

              this.onAccessTokenFetched(r.data.accessToken);
            });
          }
          const retryOriginalRequest = new Promise((resolve) => {
            this.addSubscriber((accessToken) => {
              // ** Make sure to assign accessToken according to your response.
              // ** Check: https://pixinvent.ticksy.com/ticket/2413870
              // ** Change Authorization header
              originalRequest.headers.Authorization = `${this.jwtConfig.tokenType} ${accessToken}`;
              resolve(this.axios(originalRequest));
            });
          });
          return retryOriginalRequest;
        }
        return Promise.reject(error);
      }
    );
  }

  // handle Error
  handleError(error) {
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      if (error.response.status < 500) {
        if (
          error.response.status < 500 &&
          error.response?.data?.detail !== undefined
        )
          toastWarning(error.response.data.detail);
        else toastWarning("Hệ thống đã gặp lỗi");
        if (error.response.status === 401) {
          window.location.href = "/login";
        }
      } else {
        toastWarning("Đã xảy ra lỗi Server");
      }
    } else if (error?.request) {
      // The request was made but no response was received
      // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
      // http.ClientRequest in node.js
      // // console.log(error.request)
    } else {
      // Something happened in setting up the request that triggered an Error
      toastWarning(error.message);
    }
  }

  readGroup(group) {
    let readDigit = [
      " Không",
      " Một",
      " Hai",
      " Ba",
      " Bốn",
      " Năm",
      " Sáu",
      " Bảy",
      " Tám",
      " Chín",
    ];
    var temp = "";
    if (group == "000") return "";
    temp = readDigit[parseInt(group.substring(0, 1))] + " Trăm";
    if (group.substring(1, 2) == "0")
      if (group.substring(2, 3) == "0") return temp;
      else {
        temp += " Lẻ" + readDigit[parseInt(group.substring(2, 3))];
        return temp;
      }
    else temp += readDigit[parseInt(group.substring(1, 2))] + " Mươi";
    if (group.substring(2, 3) == "5") temp += " Lăm";
    else if (group.substring(2, 3) != "0")
      temp += readDigit[parseInt(group.substring(2, 3))];
    return temp;
  }

  readMoney(num) {
    if (num == null || num == "") return "";
    let temp = "";
    while (num.length < 18) {
      num = "0" + num;
    }
    let g1 = num.substring(0, 3);
    let g2 = num.substring(3, 6);
    let g3 = num.substring(6, 9);
    let g4 = num.substring(9, 12);
    let g5 = num.substring(12, 15);
    let g6 = num.substring(15, 18);
    if (g1 != "000") {
      temp = this.readGroup(g1);
      temp += " Triệu";
    }
    if (g2 != "000") {
      temp += this.readGroup(g2);
      temp += " Nghìn";
    }
    if (g3 != "000") {
      temp += this.readGroup(g3);
      temp += " Tỷ";
    } else if ("" != temp) {
      temp += " Tỷ";
    }
    if (g4 != "000") {
      temp += this.readGroup(g4);
      temp += " Triệu";
    }
    if (g5 != "000") {
      temp += this.readGroup(g5);
      temp += " Nghìn";
    }
    temp = temp + this.readGroup(g6);
    temp = temp.replaceAll("Một Mươi", "Mười");
    temp = temp.trim();
    temp = temp.replaceAll("Không Trăm", "");
    temp = temp.trim();
    temp = temp.replaceAll("Mười Không", "Mười");
    temp = temp.trim();
    temp = temp.replaceAll("Mươi Không", "Mươi");
    temp = temp.trim();
    if (temp.indexOf("Lẻ") == 0) temp = temp.substring(2);
    temp = temp.trim();
    temp = temp.replaceAll("Mươi Một", "Mươi Mốt");
    temp = temp.trim();
    let result =
      temp.substring(0, 1).toUpperCase() + temp.substring(1).toLowerCase();
    return (result == "" ? "Không" : result) + " đồng chẵn";
  }

  numberWithCommas(x) {
    return x?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  updateTextView(_obj) {
    var num = this.getNumber(_obj.target.value);
    if (num == 0) {
      _obj.target.value = "";
    } else {
      _obj.target.value = num.toLocaleString();
    }
  }

  getNumber(_str) {
    var arr = _str.split("");
    var out = new Array();
    for (var cnt = 0; cnt < arr.length; cnt++) {
      if (isNaN(arr[cnt]) == false) {
        out.push(arr[cnt]);
      }
    }
    return Number(out.join(""));
  }

  onAccessTokenFetched(accessToken) {
    this.subscribers = this.subscribers.filter((callback) =>
      callback(accessToken)
    );
  }

  addSubscriber(callback) {
    this.subscribers.push(callback);
  }

  getToken() {
    return localStorage.getItem(this.jwtConfig.storageTokenKeyName);
  }

  getRefreshToken() {
    return localStorage.getItem(this.jwtConfig.storageRefreshTokenKeyName);
  }

  setToken(value) {
    localStorage.setItem(this.jwtConfig.storageTokenKeyName, value);
  }

  setRefreshToken(value) {
    localStorage.setItem(this.jwtConfig.storageRefreshTokenKeyName, value);
  }

  refreshToken() {
    // console.log(this.getRefreshToken());
    const refreshAxios = axios.create({
      baseURL: this.jwtConfig.endPoint,
      headers: {
        Authorization: "Bearer " + this.getRefreshToken(),
      },
    });
    return refreshAxios.post(this.jwtConfig.refreshEndpoint);
  }

  //#region Dịch vụ authencication
  login({ username, password }) {
    const params = new URLSearchParams();
    params.append("username", username);
    params.append("password", password);
    return axios({
      method: "post",
      url: this.jwtConfig.loginEndpoint,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      data: params,
    });
  }

  register(...args) {
    return axios({
      method: "post",
      url: this.jwtConfig.registerEndpoint,
      headers: {
        "Content-Type": "application/json",
      },
      data: JSON.stringify(...args),
    });
  }

  getMe(params) {
    return axios({
      method: "get",
      url: this.jwtConfig.getMeEndPoint,
      headers: {
        Authorization: `${this.jwtConfig.tokenType} ${
          this.getToken() !== null && this.getToken() !== undefined
            ? this.getToken()
            : params.accessToken
        }`,
      },
    });
  }
  //#endregion

  //#region Dịch vụ suppliers
  getListSuppliers(params) {
    return axios({
      method: "get",
      url: this.jwtConfig.getListSuppliersEndPoint(params),
    });
  }

  getSupplier(params) {
    return axios({
      method: "get",
      url: this.jwtConfig.getSupplierEndPoint(params),
    });
  }

  async createSupplier(params) {
    return axios({
      method: "post",
      url: this.jwtConfig.createSupplierEndPoint,
      headers: {
        "Content-Type": "application/json",
      },
      data: JSON.stringify(params),
    });
  }

  async editSupplier(params) {
    return axios({
      method: "patch",
      url: this.jwtConfig.editSupplierEndPoint(params),
      headers: {
        "Content-Type": "application/json",
      },
      data: JSON.stringify(params.data),
    });
  }

  async deleteSupplier(params) {
    return axios({
      method: "delete",
      url: this.jwtConfig.deleteSupplierEndPoint(params),
    });
  }
  //#endregion

  //#region Dịch vụ status devices
  getListStatusDevices() {
    return axios({
      method: "get",
      url: this.jwtConfig.getListStatusDevicesEndPoint,
    });
  }

  getStatusDevices(params) {
    return axios({
      method: "get",
      url: this.jwtConfig.getStatusDeviceEndPoint(params),
    });
  }

  createStatusDevice(params) {
    return axios({
      method: "post",
      url: this.jwtConfig.createStatusDeviceEndPoint,
      headers: {
        "Content-Type": "application/json",
      },
      data: JSON.stringify(params),
    });
  }

  editStatusDevice(params) {
    return axios({
      method: "patch",
      url: this.jwtConfig.editStatusDeviceEndPoint(params),
      headers: {
        "Content-Type": "application/json",
      },
      data: JSON.stringify(params.data),
    });
  }

  deleteStatusDevice(params) {
    return axios({
      method: "delete",
      url: this.jwtConfig.editStatusDeviceEndPoint(params),
    });
  }
  //#endregion

  //#region Dịch vụ quản lý người dùng
  getListUsers(params) {
    return axios({
      method: "get",
      url: this.jwtConfig.getListUsersEndPoint(params),
    });
  }

  getUser(params) {
    return axios({
      method: "get",
      url: this.jwtConfig.getUserEndPoint(params),
    });
  }

  addUsers(params) {
    return axios({
      method: "post",
      url: this.jwtConfig.addUserEndPoint,
      headers: {
        "Content-Type": "application/json",
      },
      data: JSON.stringify({
        ...params.data,
      }),
    });
  }

  updateInfoUser(params) {
    return axios({
      method: "patch",
      url: this.jwtConfig.editUserEndPoint(params),
      headers: {
        "Content-Type": "application/json",
      },
      data: JSON.stringify({
        ...params.data,
      }),
    });
  }

  deleteUser(params) {
    return axios({
      method: "delete",
      url: this.jwtConfig.deleteUserEndPoint(params),
    });
  }

  updateInfoMe(params) {
    return axios({
      method: "patch",
      url: this.jwtConfig.updateInfoMe,
      headers: {
        "Content-Type": "application/json",
      },
      data: JSON.stringify({
        ...params.data,
      }),
    });
  }

  updateAvatarMe(params) {
    const formData = new FormData();
    formData.append("file", params.data);
    return axios({
      method: "patch",
      url: this.jwtConfig.updateAvatarMe,
      headers: {
        "Content-Type": "multipart/form-data",
      },
      data: formData,
    });
  }

  updatePassword(params) {
    return axios({
      method: "patch",
      url: this.jwtConfig.updatePassword,
      headers: {
        "Content-Type": "application/json",
      },
      data: JSON.stringify({
        ...params.data,
      }),
    });
  }
  //#endregion

  //#region Dịch vụ chức vụ và phân quyền
  getRoles(params) {
    return axios({
      method: "get",
      url: this.jwtConfig.getListRolesEndPoint(params),
    });
  }

  getRole(params) {
    return axios({
      method: "get",
      url: this.jwtConfig.getRoleEndPoint(params),
    });
  }

  getRoleAdmin(params) {
    return axios({
      method: "get",
      url: this.jwtConfig.getRoleAdminEndPoint(params),
    });
  }

  createNewRole(params) {
    return axios({
      method: "post",
      url: this.jwtConfig.postNewRoleEndPoint,
      headers: {
        "Content-Type": "application/json",
      },
      data: JSON.stringify({
        name: params.name,
        slug: "",
      }),
    });
  }

  updateRole(params) {
    return axios({
      method: "patch",
      url: this.jwtConfig.updateRoleEndPoint(params),
      headers: {
        "Content-Type": "application/json",
      },
      data: JSON.stringify({
        name: params.name,
        slug: "",
      }),
    });
  }

  updatePermission(params) {
    return axios({
      method: "patch",
      url: this.jwtConfig.updatePermissionForRoleEndPoint(params),
      headers: {
        "Content-Type": "application/json",
      },
      data: JSON.stringify({
        enable_permission_ids: params.checked,
        disable_permission_ids: params.unChecked,
      }),
    });
  }

  deleteRole(params) {
    return axios({
      method: "delete",
      url: this.jwtConfig.deleteRoleEndPoint(params),
    });
  }

  getPermissionMatrix() {
    return axios.get(this.jwtConfig.getPermissionMatrixEndPoint);
  }
  //#endregion

  //#region Dịch vụ Position
  getListPositions(params) {
    return axios.get(this.jwtConfig.getListPositionsEndPoint, { params });
  }

  getPosition(params) {
    return axios({
      method: "get",
      url: this.jwtConfig.getPositionWithIdEndPoint(params),
    });
  }

  updatePositionInfo(params) {
    return axios({
      method: "patch",
      url: this.jwtConfig.updatePositionInfoEndPoint(params),
      headers: {
        "Content-Type": "application/json",
      },
      data: JSON.stringify({
        name: params.name,
        department_id: params.department_id,
      }),
    });
  }
  addPosition(params) {
    return axios({
      method: "post",
      url: this.jwtConfig.addPositionForInfoEndPoint,
      headers: {
        "Content-Type": "application/json",
      },
      data: JSON.stringify({
        name: params.name,
        // slug: params.slug,
        department_id: params.department_id,
      }),
    });
  }

  deletePosition(params) {
    return axios({
      method: "delete",
      url: this.jwtConfig.deletePositionEndPoint(params),
    });
  }
  updatePermissionForPosition(params) {
    return axios({
      method: "patch",
      url: this.jwtConfig.updatePermissionForPositionEndPoint(params),
      headers: {
        "Content-Type": "application/json",
      },
      data: JSON.stringify({
        enable_permission_ids: params.enable_permission_ids,
        disable_permission_ids: params.disable_permission_ids,
      }),
    });
  }
  //#endregion

  //#region Dịch vụ department
  getListDepartment(params) {
    return axios.get(this.jwtConfig.getListDepartmentsEndPoint, {
      params: { size: 2 * 10e6 },
    });
  }

  getDepartment(params) {
    return axios({
      method: "get",
      url: this.jwtConfig.getDepartmentEndPoint(params),
    });
  }

  createDepartmentForInfo(params) {
    return axios({
      method: "post",
      url: this.jwtConfig.createDepartmentForInfoEndPoint,
      headers: {
        "Content-Type": "application/json",
      },
      data: JSON.stringify({
        name: params.name,
        // slug: params.slug,
        parent_department_id: params.parent_department_id,
      }),
    });
  }

  updateDepartmentForPermission(params) {
    return axios({
      method: "patch",
      url: this.jwtConfig.updateDepartmentForPermissionEndPoint(params),
      headers: {
        "Content-Type": "application/json",
      },
      params: {
        department_id: params.id,
      },
      data: JSON.stringify({
        enable_permission_ids: [...params.enable_permission_ids],
        disable_permission_ids: [...params.disable_permission_ids],
      }),
    });
  }

  editDepartment(params) {
    // console.log("aaa", params)
    return axios({
      method: "patch",
      url: this.jwtConfig.editDepartmentEndPoint(params),
      headers: {
        "Content-Type": "application/json",
      },
      data: JSON.stringify({
        name: params.data.name,
        slug: "",
      }),
    });
  }

  deleteDepartment(params) {
    return axios({
      method: "delete",
      url: this.jwtConfig.deleteDepartmentEndPoint(params),
    });
  }
  //#endregion

  //#region Dịch vụ units
  getListUnit(params) {
    return axios({
      method: "get",
      url: this.jwtConfig.getListUnitsEndPoint(params),
    });
  }

  getUnit(params) {
    return axios({
      method: "get",
      url: this.jwtConfig.getUnitEndPoint(params),
    });
  }

  createUnit(params) {
    return axios({
      method: "post",
      url: this.jwtConfig.createUnitEndPoint,
      headers: {
        "Content-Type": "application/json",
      },
      data: JSON.stringify({
        name: params.name,
        slug: "",
      }),
    });
  }

  editUnit(params) {
    // console.log("aaa", params)
    return axios({
      method: "patch",
      url: this.jwtConfig.editUnitEndPoint(params),
      headers: {
        "Content-Type": "application/json",
      },
      data: JSON.stringify({
        name: params.data.name,
        slug: "",
      }),
    });
  }

  deleteUnit(params) {
    return axios({
      method: "delete",
      url: this.jwtConfig.deleteUnitEndPoint(params),
    });
  }
  //#endregion

  //#region Dịch vụ Products
  getListProducts(params) {
    return axios({
      method: "get",
      url: this.jwtConfig.getListProductsEndPoint(params),
    });
  }

  getListProductWithVariants(params) {
    return axios.get(this.jwtConfig.getListProductsVariantsEndPoint, {
      params: {
        page: params.page,
        size: params.perPage,
        is_deleted: false,
        order_by: "id DESC",
      },
    });
  }

  getProduct(params) {
    return axios({
      method: "get",
      url: this.jwtConfig.getProductVariantsEndPoint(params),
    });
  }

  addProductInfo(params) {
    return axios({
      method: "post",
      url: this.jwtConfig.addProductInfoEndPoint,
      headers: {
        "Content-Type": "application/json",
      },
      data: JSON.stringify({
        ...params,
      }),
    });
  }

  updateProduct(params) {
    return axios({
      method: "patch",
      url: this.jwtConfig.updateProductInfoEndPoint(params),
      headers: {
        "Content-Type": "application/json",
      },
      data: JSON.stringify({
        ...params,
      }),
    });
  }

  updateResourcesProduct(params) {
    // console.log("===", params)
    return axios({
      method: "patch",
      url: this.jwtConfig.updateResourceProductEndPoint(params),
      headers: {
        "Content-Type": "application/json",
      },
      data: JSON.stringify([...params.idsImage]),
    });
  }

  deleteProduct(params) {
    return axios({
      method: "delete",
      url: this.jwtConfig.deleteProductEndPoint(params),
    });
  }
  //#endregion

  //#region Dịch vụ giảm giá
  getListDiscounts(params) {
    return axios({
      method: "get",
      url: this.jwtConfig.getDiscountsEndPoint(params),
    });
  }

  getDiscount(params) {
    return axios({
      method: "get",
      url: this.jwtConfig.getDiscountEndPoint(params),
    });
  }

  addDiscount(params) {
    return axios({
      method: "post",
      url: this.jwtConfig.addDiscountEndPoint,
      headers: {
        "Content-Type": "application/json",
      },
      data: JSON.stringify({
        ...params,
      }),
    });
  }

  editDiscount(params) {
    return axios({
      method: "patch",
      url: this.jwtConfig.editDiscountEndPoint(params),
      headers: {
        "Content-Type": "application/json",
      },
      data: JSON.stringify({
        ...params.data,
      }),
    });
  }

  deleteDiscount(params) {
    return axios({
      method: "delete",
      url: this.jwtConfig.deleteDiscountEndPoint(params),
    });
  }
  //#endregion

  //#region Dịch vụ kiểm kho
  getListInventoryStock(params) {
    return axios({
      method: "get",
      url: this.jwtConfig.getListInventoryEndPoint(params),
    });
  }

  getInventory(params) {
    return axios({
      method: "get",
      url: this.jwtConfig.getInventoryEndPoint(params),
    });
  }

  updateInventoryInfo(params) {
    return axios({
      method: "patch",
      url: this.jwtConfig.updateInventoryEndPoint(params),
      headers: {
        "Content-Type": "application/json",
      },
      data: JSON.stringify({
        ...params.data,
      }),
    });
  }

  updateInventoryListProduct(params) {
    return axios({
      method: "patch",
      url: this.jwtConfig.updateInventoryListProductEndPoint(params),
      headers: {
        "Content-Type": "application/json",
      },
      data: JSON.stringify([...params.data]),
    });
  }

  updateInventoryStatus(params) {
    return axios({
      method: "patch",
      url: this.jwtConfig.updateInventoryStatusEndPoint(params),
      headers: {
        "Content-Type": "application/json",
      },
      data: JSON.stringify({
        ...params.data,
      }),
    });
  }

  addInventoryStock(params) {
    return axios({
      method: "post",
      url: this.jwtConfig.addInventoryEndPoint,
      headers: {
        "Content-Type": "application/json",
      },
      data: JSON.stringify({
        ...params,
      }),
    });
  }
  //#endregion

  //#region Dịch vụ kho hàng
  getListStock(params) {
    return axios({
      method: "get",
      url: this.jwtConfig.getListStockEndPoint(params),
    });
  }

  getStock(params) {
    return axios({
      method: "get",
      url: this.jwtConfig.getStockEndPoint(params),
    });
  }
  //#endregion

  //#region Dịch vụ phiếu nhập hàng
  addGoodReceivedNote(params) {
    return axios({
      method: "post",
      url: this.jwtConfig.addGoodReceivedNoteEndPoint,
      headers: {
        "Content-Type": "application/json",
      },
      data: JSON.stringify({
        ...params.data,
      }),
    });
  }

  getListGoodReceivedNotes(params) {
    console.log(
      "🚀 ~ file: jwtService.js:920 ~ getListGoodReceivedNotes ~ params:",
      params
    );
    return axios({
      method: "get",
      url: this.jwtConfig.getListGoodReceivedNoteEndPoint,
      params: {
        ...(params.q ? { search: params.q } : {}),
        page: params.page,
        size: params.perPage,
        order_by: "id DESC",
        is_deleted: false,
        ...(params.status && params.status.length !== 0
          ? {
              status: Array.isArray(params.status)
                ? params.status.join(",")
                : params.status,
            }
          : {}),
      },
    });
  }

  getGoodReceivedNote(params) {
    return axios({
      method: "get",
      url: this.jwtConfig.getGoodReceivedNoteEndPoint(params),
    });
  }

  updateGoodReceivedNote(params) {
    let url = "";
    switch (params.status) {
      case "approval_date":
        url = this.jwtConfig.updateGoodReceivedNoteEndPoint(params);
        break;
      case "import_date":
        url = this.jwtConfig.updateImportGoodReceivedNoteEndPoint(params);
        break;
      case "invoice_id":
        url = this.jwtConfig.updatePaymentGoodReceivedNoteEndPoint(params);
        break;
      default:
        url = this.jwtConfig.updateGoodReceivedNoteEndPoint(params);
        break;
    }
    return axios({
      method: "patch",
      url,
      headers: {
        "Content-Type": "application/json",
      },
      data: JSON.stringify({
        ...params.data,
      }),
    });
  }

  deleteGoodReceivedNote(params) {
    return axios({
      method: "delete",
      url: this.jwtConfig.deleteGoodReceivedNoteEndPoint(params),
    });
  }
  //#endregion

  //#region Dịch vụ Export Note
  getListExportNote(params) {
    return axios({
      method: "get",
      url: this.jwtConfig.getListExportNoteEndPoint(params),
    });
  }

  getExportNote(params) {
    return axios({
      method: "get",
      url: this.jwtConfig.getExportNoteEndPoint(params),
    });
  }

  addExportNote(params) {
    return axios({
      method: "post",
      url: this.jwtConfig.addExportNoteEndPoint,
      headers: {
        "Content-Type": "application/json",
      },
      data: JSON.stringify({
        ...params,
      }),
    });
  }

  updateExportNote(params) {
    return axios({
      method: "patch",
      url: this.jwtConfig.updateExportNoteEndPoint(params),
      headers: {
        "Content-Type": "application/json",
      },
      data: JSON.stringify({
        ...params.data,
      }),
    });
  }
  //#endregion

  //#region Dịch vụ product variant
  getListProductVariant(params) {
    return axios({
      method: "get",
      url: this.jwtConfig.getListProductVariantEndPoint(params),
    });
  }

  getProductVariant(params) {
    return axios({
      method: "get",
      url: this.jwtConfig.getProductVariantEndPoint(params),
    });
  }

  addProductVariant(params) {
    // // console.log("params", params)
    return axios({
      method: "post",
      url: this.jwtConfig.addProductVariantEndPoint,
      headers: {
        "Content-Type": "application/json",
      },
      data: JSON.stringify({
        ...params,
      }),
    });
  }

  addExchangeProductVariant(params) {
    // // console.log("params", params)
    return axios({
      method: "post",
      url: this.jwtConfig.addExchangeProductVariantEndPoint(params),
      headers: {
        "Content-Type": "application/json",
      },
      data: JSON.stringify({
        ...params.data,
      }),
    });
  }

  updateProductVariant(params) {
    return axios({
      method: "patch",
      url: this.jwtConfig.updateProductVariantEndPoint(params),
      headers: {
        "Content-Type": "application/json",
      },
      data: JSON.stringify({
        ...params,
      }),
    });
  }

  deleteProductVariant(params) {
    return axios({
      method: "delete",
      url: this.jwtConfig.deleteProductVariantEndPoint(params),
    });
  }
  //#endregion

  //#region Dịch vụ hỉnh ảnh sản phẩm
  getListResourceProduct(params) {
    return axios({
      method: "get",
      url: this.jwtConfig.getListResourceProductsEndPoint(params),
    });
  }

  addResourceProduct(params) {
    const formData = new FormData();
    params.map((item) => {
      formData.append("files", item);
    });
    return axios({
      method: "post",
      url: this.jwtConfig.addResourceProductEndPoint,
      headers: {
        "Content-Type": "multipart/form-data",
      },
      data: formData,
    });
  }

  deleteResourceProduct(params) {
    return axios({
      method: "delete",
      url: this.jwtConfig.deleteResourceProductEndPoint(params),
    });
  }
  //#endregion

  //#region Dịch vụ Tags
  getListTags(params) {
    return axios({
      method: "get",
      url: this.jwtConfig.getListTagsEndPoint(params),
    });
  }

  addTag(params) {
    return axios({
      method: "post",
      url: this.jwtConfig.addTagEndPoint,
      headers: {
        "Content-Type": "application/json",
      },
      data: JSON.stringify({
        ...params,
      }),
    });
  }

  getTag(params) {
    return axios({
      method: "get",
      url: this.jwtConfig.getTagEndPoint(params),
    });
  }

  editTag(params) {
    return axios({
      method: "patch",
      url: this.jwtConfig.editTagEndPoint(params),
      headers: {
        "Content-Type": "application/json",
      },
      data: JSON.stringify({
        ...params.data,
      }),
    });
  }

  deleteTag(params) {
    return axios({
      method: "delete",
      url: this.jwtConfig.deleteTagEndPoint(params),
    });
  }
  //#endregion

  //#region Dịch vụ Payment method
  getListPaymentMethods(params) {
    return axios({
      method: "get",
      url: this.jwtConfig.getListPaymentMethodsEndPoint(params),
    });
  }

  addPaymentMethod(params) {
    return axios({
      method: "post",
      url: this.jwtConfig.addPaymentMethodEndPoint,
      headers: {
        "Content-Type": "application/json",
      },
      data: JSON.stringify({
        ...params,
      }),
    });
  }

  getPaymentMethod(params) {
    return axios({
      method: "get",
      url: this.jwtConfig.getPaymentMethodEndPoint(params),
    });
  }

  editPaymentMethod(params) {
    return axios({
      method: "patch",
      url: this.jwtConfig.editPaymentMethodEndPoint(params),
      headers: {
        "Content-Type": "application/json",
      },
      data: JSON.stringify({
        ...params.data,
      }),
    });
  }

  deletePaymentMethod(params) {
    return axios({
      method: "delete",
      url: this.jwtConfig.deletePaymentMethodEndPoint(params),
    });
  }
  //#endregion

  //#region Dịch vụ Types
  getListTypes(params) {
    return axios({
      method: "get",
      url: this.jwtConfig.getListTypesEndPoint(params),
    });
  }

  addType(params) {
    return axios({
      method: "post",
      url: this.jwtConfig.addTypeEndPoint,
      headers: {
        "Content-Type": "application/json",
      },
      data: JSON.stringify({
        ...params,
      }),
    });
  }

  getType(params) {
    return axios({
      method: "get",
      url: this.jwtConfig.getTypeEndPoint(params),
    });
  }

  editType(params) {
    return axios({
      method: "patch",
      url: this.jwtConfig.editTypeEndPoint(params),
      headers: {
        "Content-Type": "application/json",
      },
      data: JSON.stringify({
        ...params.data,
      }),
    });
  }

  deleteType(params) {
    return axios({
      method: "delete",
      url: this.jwtConfig.deleteTypeEndPoint(params),
    });
  }
  //#endregion

  //#region Dịch vụ invoice
  addInvoice(params) {
    return axios({
      method: "post",
      url: this.jwtConfig.addInvoiceEndPoint,
      headers: {
        "Content-Type": "application/json",
      },
      data: JSON.stringify({
        ...params.data,
      }),
    });
  }
  //#endregion

  //#region Dịch vụ Brands
  getListBrands(params) {
    return axios({
      method: "get",
      url: this.jwtConfig.getListBrandsEndPoint(params),
    });
  }

  addBrand(params) {
    return axios({
      method: "post",
      url: this.jwtConfig.addBrandEndPoint,
      headers: {
        "Content-Type": "application/json",
      },
      data: JSON.stringify({
        ...params,
      }),
    });
  }

  getBrand(params) {
    return axios({
      method: "get",
      url: this.jwtConfig.getBrandEndPoint(params),
    });
  }

  editBrand(params) {
    return axios({
      method: "patch",
      url: this.jwtConfig.editBrandEndPoint(params),
      headers: {
        "Content-Type": "application/json",
      },
      data: JSON.stringify({
        ...params.data,
      }),
    });
  }

  deleteBrand(params) {
    return axios({
      method: "delete",
      url: this.jwtConfig.deleteBrandEndPoint(params),
    });
  }
  //#endregion

  //#region Dịch vụ Taxs
  getListTaxs(params) {
    return axios({
      method: "get",
      url: this.jwtConfig.getListTaxsEndPoint(params),
    });
  }

  addTax(params) {
    return axios({
      method: "post",
      url: this.jwtConfig.addTaxEndPoint,
      headers: {
        "Content-Type": "application/json",
      },
      data: JSON.stringify({
        ...params,
      }),
    });
  }

  getTax(params) {
    return axios({
      method: "get",
      url: this.jwtConfig.getTaxEndPoint(params),
    });
  }

  editTax(params) {
    return axios({
      method: "patch",
      url: this.jwtConfig.editTaxEndPoint(params),
      headers: {
        "Content-Type": "application/json",
      },
      data: JSON.stringify({
        ...params.data,
      }),
    });
  }

  deleteTax(params) {
    return axios({
      method: "delete",
      url: this.jwtConfig.deleteTaxEndPoint(params),
    });
  }
  //#endregion

  //#region Dịch vụ properties
  getListProperties(params) {
    return axios({
      method: "get",
      url: this.jwtConfig.getListPropertiesEndPoint(params),
    });
  }

  addProperty(params) {
    return axios({
      method: "post",
      url: this.jwtConfig.addPropertyEndPoint,
      headers: {
        "Content-Type": "application/json",
      },
      data: JSON.stringify({
        ...params,
      }),
    });
  }

  getProperty(params) {
    return axios({
      method: "get",
      url: this.jwtConfig.getPropertyEndPoint(params),
    });
  }

  editProperty(params) {
    return axios({
      method: "patch",
      url: this.jwtConfig.editPropertyEndPoint(params),
      headers: {
        "Content-Type": "application/json",
      },
      data: JSON.stringify({
        ...params.data,
      }),
    });
  }

  deleteProperty(params) {
    return axios({
      method: "delete",
      url: this.jwtConfig.deletePropertyEndPoint(params),
    });
  }
  //#endregion
  
  //#region Dịch vụ Price Policy
  getListPricePolicy(params) {
    return axios({
      method: "get",
      url: this.jwtConfig.getListPricePolicyEndPoint(params),
    });
  }
  addPricePolicy(params) {
    return axios({
      method: "post",
      url: this.jwtConfig.addPricePolicyEndPoint,
      headers: {
        "Content-Type": "application/json",
      },
      data: JSON.stringify({
        ...params,
      }),
    });
  }

  getPricePolicy(params) {
    return axios({
      method: "get",
      url: this.jwtConfig.getPricePolicyEndPoint(params),
    });
  }

  editPricePolicy(params) {
    return axios({
      method: "patch",
      url: this.jwtConfig.editPricePolicyEndPoint(params),
      headers: {
        "Content-Type": "application/json",
      },
      data: JSON.stringify({
        ...params.data,
      }),
    });
  }

  deletePricePolicy(params) {
    return axios({
      method: "delete",
      url: this.jwtConfig.deletePricePolicyEndPoint(params),
    });
  }
  //#endregion

  //#region Dịch vụ Branch
  getListBranch(params) {
    return axios({
      method: "get",
      url: this.jwtConfig.getListBranchEndPoint(params),
    });
  }

  addBranch(params) {
    return axios({
      method: "post",
      url: this.jwtConfig.addBranchEndPoint,
      headers: {
        "Content-Type": "application/json",
      },
      data: JSON.stringify({
        ...params,
      }),
    });
  }

  getBranch(params) {
    return axios({
      method: "get",
      url: this.jwtConfig.getBranchEndPoint(params),
    });
  }

  editBranch(params) {
    return axios({
      method: "patch",
      url: this.jwtConfig.editBranchEndPoint(params),
      headers: {
        "Content-Type": "application/json",
      },
      data: JSON.stringify({
        ...params.data,
      }),
    });
  }

  deleteBranch(params) {
    return axios({
      method: "delete",
      url: this.jwtConfig.deleteBranchEndPoint(params),
    });
  }

  uploadResource(params) {
    const formData = new FormData();
    for (const file of params) {
      formData.append("files", file);
    }
    return axios({
      method: "post",
      headers: {
        "Content-Type": "multipart/form-data",
      },
      url: this.jwtConfig.uploadResourceEndPoint(),
      data: formData,
    });
  }
  //#endregion

  //#region Danh sách thiết bị
  getListDevices(params) {
    return axios({
      method: "get",
      url: this.jwtConfig.getListDevicesEndPoint(params),
    });
  }

  getDevice(params) {
    // console.log(params)
    return axios({
      method: "get",
      url: this.jwtConfig.getDeviceEndPoint(params),
    });
  }

  addDevice(params) {}
  //#endregion

  //#region lịch sử thiết bị
  getAllDeviceHistory(params) {
    return axios({
      method: "get",
      url: this.jwtConfig.getAllDeviceHistoryEndPoint(params),
    });
  }
  getDeviceHistory(params) {
    // console.log(params)
    return axios({
      method: "get",
      url: this.jwtConfig.getDeviceHistoryEndPoint(params),
    });
  }
  addDeviceHistory(params) {
    return axios({
      method: "post",
      headers: {
        "Content-Type": "application/json",
      },
      url: this.jwtConfig.addDeviceHistoryEndPoint(),
      data: params,
    });
  }
  getOneDeviceHistory(params) {
    return axios({
      method: "get",
      url: this.jwtConfig.getOneHistoryEndPoint(params),
    });
  }
  //#endregion

  //#region Payment request
  getPaymentRequestList(params) {
    return axios({
      method: "get",
      url: this.jwtConfig.getListRequestEndPoint(params),
    });
  }
  createPaymentRequest(params) {
    return axios({
      method: "post",
      headers: {
        "Content-Type": "application/json",
      },
      url: this.jwtConfig.adminCreateRequestEndPoint(params),
      data: {
        total: params.total,
        type: params.type,
      },
    });
  }
  getPaymentRequest(params) {
    return axios({
      method: "get",
      url: this.jwtConfig.getRequestEndPoint(params),
    });
  }
  updatePaymentRequestStatus(params) {
    return axios({
      method: "patch",
      headers: {
        "Content-Type": "application/json",
      },
      url: this.jwtConfig.updateRequestEndPoint(params),
      data: {
        status: params.status,
      },
    });
  }
  //#endregion

  //#region 
  getListCartPriceRule(params) {
    return axios.get(
      this.jwtConfig.getListCartPriceRuleEndPoint,
      {
        params
      }
    )
  }
  createCartPriceRule(data) {
    return axios.post(
      this.jwtConfig.createCartPriceRuleEndPoint,
      data
    )
  }
  getCartPriceRuleById(id) {
    return axios.get(
      this.jwtConfig.getCartPriceRuleEndPoint.replace('{cart_price_rule_id}', id)
    )
  }
  updateCartPriceRule(id, data) {
    return axios.put(
      this.jwtConfig.getCartPriceRuleEndPoint.replace('{cart_price_rule_id}', id),
      data
    )
  }
  //#endregion
}
