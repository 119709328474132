// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import useJwt from '@src/auth/jwt/useJwt'
import { closeSwal, handleError, handleLoading, handleQuestion, handleSuccess, toastSuccess, toastWarning } from '../../sweetalert'

export const getData = createAsyncThunk("products/getData", async (params) => {
  try {
    params.setIsShowLoading(true);
    const response = await useJwt.getListProductWithVariants(params);
    let data = response.data.data;
    // const requests = response.data.data.map(async (item, index) => {
    //   const response_2 = await useJwt.getProduct({
    //     id: item.id,
    //   });
    //   data[index].detail = response_2.data.data;
    // });
    // await Promise.all(requests);
    params.setIsShowLoading(false);
    console.log("🚀 ~ file: index.js:11 ~ getData ~ data:", data)
    return {
      allData: data,
      data: data,
      totalPages: response.data.total_page,
      params,
    };
  } catch (error) {
    params.setIsShowLoading(false);
    return {
      allData: [],
      data: [],
      totalPages: 1,
      params,
    };
  }
});

export const createProduct = createAsyncThunk('products/create', async params => {
  try {
    handleLoading("Vui lòng chờ hệ thống...")
    // params.setBlock(true)
    let data = params.data
    if (params.data.resource_upload.length > 0) {
      const response_1 = await useJwt.addResourceProduct(params.data.resource_upload)
  
      let arrIdsImage = response_1.data?.map(item => item.id)
      data.resource_ids = [
        ...data.resource_ids,
        ...arrIdsImage
      ]
      delete data.resource_upload
    }

    await useJwt.addProductInfo(data)
    closeSwal()
    toastSuccess("Thêm thành công")
    // params.setBlock(false)
    if (!params.checkCreate) {
      params.navigateFunction()
    }
    // dispatch(getData(getState().products.params))
    // params.reset()
  } catch (error) {
    closeSwal()
    const arrDeleteImages = response_1.data?.map(async item => {
      return await useJwt.deleteResourceProduct({
        id: item.id
      })
    })
    await Promise.resolve(arrDeleteImages)
    params.setBlock(false)
    useJwt.handleError(error)
  }
})

export const updateProduct = createAsyncThunk('products/update', async params => {
  try {
    handleLoading("Vui lòng chờ hệ thống...")
    params.setBlock(true)
    let data = params.data
    if (params.data.imagesUpload.length > 0) {
      const response_1 = await useJwt.addResourceProduct(params.data.imagesUpload)
  
      let arrIdsImage = response_1.data?.map(item => item.id)
      data.imagesChoice = [
        ...data.imagesChoice,
        ...arrIdsImage
      ]
      delete data.imagesUpload
    }
    console.log(data.step_1)
    await useJwt.updateProduct(data.step_1)
    await useJwt.updateResourcesProduct({
      id: data.id,
      idsImage: data.imagesChoice
    })
    closeSwal()
    toastSuccess("Chỉnh sửa thành công")
    params.setBlock(false)
    if (!params.checkCreate) {
      params.navigateFunction()
    }

    dispatch(getData(getState().products.params))
    // params.reset()
  } catch (error) {
    closeSwal()
    const arrDeleteImages = response_1.data?.map(async item => {
      return await useJwt.deleteResourceProduct({
        id: item.id
      })
    })
    await Promise.resolve(arrDeleteImages)
    params.setBlock(false)
    useJwt.handleError(error)
  }
})

export const updateProductVariant = createAsyncThunk('product/updateVariant', async params => {
  try {
    params?.setBlock(true)
    const response = await useJwt.updateProductVariant(params.data)
    toastSuccess("Chỉnh sửa thành công")
    params?.setBlock(false)
  } catch (error) {
    useJwt.handleError(error)
  }
})

export const createProductVariant = createAsyncThunk('products/createVariant', async params => {
  try {
    handleLoading("Vui lòng chờ hệ thống...")
    let data = params.data
    // console.log("=>data", data)
    if (data.obj_in.input_tax_id === "")
      delete data.obj_in.input_tax_id
    if (data.obj_in.output_tax_id === "")
      delete data.obj_in.output_tax_id

    if (data.obj_in.imagesUpload?.length > 0) {
      const response_1 = await useJwt.addResourceProduct(data.obj_in.imagesUpload)
  
      let arrIdsImage = response_1.data?.map(item => item.id)

      data.obj_in.image = arrIdsImage?.[0]
    }

    if(data.obj_in.imagesChoice) {
      data.obj_in.image = data.obj_in.imagesChoice
    }

    delete data.obj_in.imagesUpload
    delete data.obj_in.imagesChoice

    await useJwt.addProductVariant(data)
    closeSwal()
    toastSuccess("Thêm thành công")
    if (!params.checkCreate) {
      params.navigateFunction()
    }
  } catch (error) {
    closeSwal()
    // const arrDeleteImages = response_1.data?.map(async item => {
    //   return await useJwt.deleteResourceProduct({
    //     id: item.id
    //   })
    // })
    // await Promise.resolve(arrDeleteImages)
    // params.setBlock(false)
    useJwt.handleError(error)
  }
})

export const createExchangeProductVariant = createAsyncThunk('products/createVariant', async params => {
  try {
    handleLoading("Vui lòng chờ hệ thống...")
    let data = params.data

    if (data.data.imagesUpload?.length > 0) {
      const response_1 = await useJwt.addResourceProduct(params.data.data.imagesUpload)
  
      let arrIdsImage = response_1.data?.map(item => item.id)

      data.data.image = arrIdsImage?.[0]
    }

    if(data.data.imagesChoice) {
      data.data.image = data.data.imagesChoice
    }

    delete data.data.imagesUpload
    delete data.data.imagesChoice

    await useJwt.addExchangeProductVariant(data)
    closeSwal()
    toastSuccess("Thêm thành công")
    if (!params.checkCreate) {
      params.navigateFunction()
    }
  } catch (error) {
    closeSwal()
    useJwt.handleError(error)
  }
})

export const getProduct = createAsyncThunk('products/getProduct', async params => {
  try {
    params.setBlock(true)
    const response = await useJwt.getProduct(params.data)
    params.setBlock(false)
    return {
      productChoice: response.data.data,
      paramsProductChoice: params
    }
  } catch (error) {
    useJwt.handleError(error)
  }
})

export const deleteProduct = createAsyncThunk('products/deleteProduct', async (params, {dispatch, getState}) => {
  try {
    handleLoading("Vui lòng chờ hệ thống...")
    await useJwt.deleteProduct(params)
    await dispatch(
      getData(getState().products.params)
    )
    closeSwal()
    toastSuccess("Xóa thành công")
  } catch (error) {
    closeSwal()
    handleError(error)
  }
})

export const deleteProductVariant = createAsyncThunk('product/deleteProductVariant', async (params, {dispatch, getState}) => {
  try {
    handleQuestion("Bạn muốn tiếp tục?", "Đây là hành động vô hiệu hóa vĩnh viễn phiên bản này.", async (isConfirmed) => {
      try {
        handleLoading("Vui lòng chờ hệ thống...")
        await useJwt.deleteProductVariant(params)
        await dispatch(
          getData(getState().products.params)
        )
        toastSuccess("Xóa thành công")
        closeSwal()
      } catch (error) {
        closeSwal()
      }
    })
  } catch (error) {
    closeSwal()
    useJwt.handleError(error)
  }
})

export const getProductVariant = createAsyncThunk('products/getProductVariant', async (params) => {
  try {
    params.setBlock(true)
    const response = await useJwt.getProductVariant(params.data)
    console.log("response", response)
    params.setBlock(false)
    return {
      productVariantChoice: {
        ...response.data.data,
      },
      paramsProductVariantChoice: params
    }
  } catch (error) {
    useJwt.handleError(error)
    return {
      productVariantChoice: {},
      paramsProductVariantChoice: {}
    }
  }
})
export const productsSlice = createSlice({
  name: 'products',
  initialState: {
    data: [],
    total: 1,
    params: {},
    allData: [],
    productChoice: {},
    paramsProductChoice: {},
    productVariantChoice: {},
    paramsProductVariantChoice: {}
  },
  reducers: {
    changeVariantChoice: (state, action) => {
      state.productVariantChoice.id = action.payload.id

      const variantChoice = state.productChoice.variants?.find(_ => _.id === action.payload.id)

      if(variantChoice) {
        state.productVariantChoice = variantChoice
      }
    }
  },
  extraReducers: builder => {
    builder.addCase(getData.fulfilled, (state, action) => {
      state.data = action.payload.data
      state.params = action.payload.params
      state.allData = action.payload.allData
      state.total = action.payload.totalPages
    })
    .addCase(getProduct.fulfilled, (state, action) => {
      console.log("🚀 ~ file: index.js:274 ~ .addCase ~ action:", action)
      if(action.payload) {
        state.productChoice = action.payload.productChoice
        state.paramsProductChoice = action.payload.paramsProductChoice
        if(state.productVariantChoice.id) {
          const variantChoice = state.productChoice.variants?.find(_ => _.id === state.productVariantChoice.id)
  
          if(variantChoice) {
            state.productVariantChoice = variantChoice
          }
        }
        else {
          state.productVariantChoice = state.productChoice.variants?.[0]
        }
      }
    })
    .addCase(getProduct.rejected, (state, action) => {
      toastWarning(action.error)
    })
    .addCase(getProductVariant.fulfilled, (state, action) => {
      // console.log("payload", action.payload)
      state.productVariantChoice = action.payload.productVariantChoice
      state.paramsProductVariantChoice = action.payload.paramsProductVariantChoice
    })
  }
})
export const actions = productsSlice.actions
export default productsSlice.reducer