// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import useJwt from '@src/auth/jwt/useJwt'
import { closeSwal, handleError, handleLoading, toastSuccess } from '../../sweetalert'

export const getData = createAsyncThunk('types/getData', async params => {
  try {
    params.setIsLoading(true)
    const response = await useJwt.getListTypes(params)
    params.setIsLoading(false)
    return { 
      allData: response.data.data, 
      data: response.data.data, 
      totalPages: response.data.total_page, 
      params 
    }
    
  } catch (error) {
    useJwt.handleError(error)
    if (params.setIsLoading !== null && params.setIsLoading !== undefined)
      params.setIsLoading(false)
    return {
      allData: [], 
      data: [], 
      totalPages: 1, 
      params 
    }
  }
})

export const getType = createAsyncThunk('types/getType', async params => {
  try {
    params.setBlock(true)
    const response = await useJwt.getType(params)
    params.setBlock(false)
    return { 
      typeChoice: response.data.data, 
      paramsType: params
    }
  } catch (error) {
    useJwt.handleError(error)
    return {
      typeChoice: {},
      paramsType: params
    }
  }
})

export const createType = createAsyncThunk('types/create', async (params, {dispatch, getState}) => {
  try {
    params.setBlock(true)
    await useJwt.addType(params.data)
    params.setBlock(false)
    toastSuccess("Tạo thành công")
    if (!params.checkCreate) {
      params.navigateFunction()
    }
    if (params.isReload !== undefined && params.isReload === true) {
      const paramsTypes = getState().types.params
      dispatch(getData(paramsTypes))
    }
    params.reset()
  } catch (error) {
    useJwt.handleError(error)
  }
})

export const editType = createAsyncThunk('types/edit', async (params, {dispatch, getState}) => {
  try {
    params.setBlock(true)
    const response = await useJwt.editType(params.data)
    params.setBlock(false)
    toastSuccess("Chỉnh sửa thành công")
    if (params.checkCreate) {
        params.navigateFunction()
    }
    dispatch(
      getType(getState().types.paramsType)
    )
  } catch (error) {
    closeSwal()
    useJwt.handleError(error)
    params.reset()
  }
})

export const deleteType = createAsyncThunk('types/delete', async (params, {getState, dispatch}) => {
  try {
    handleLoading("Vui lòng chờ hệ thống...")
    await useJwt.deleteType(params)
    const store = getState().types
    await dispatch(getData(store.params))
    closeSwal()
    toastSuccess("Xóa thành công")
  } catch (error) {
    closeSwal()
    useJwt.handleError(error)
  }
})

export const typeSlice = createSlice({
  name: 'types',
  initialState: {
    data: [],
    total: 1,
    params: {},
    allData: [],
    typeChoice: {},
    paramsType: {}
  },
  reducers: {},
  extraReducers: builder => {
    builder.addCase(getData.fulfilled, (state, action) => {
      state.data = action.payload.data
      state.params = action.payload.params
      state.allData = action.payload.allData
      state.total = action.payload.totalPages
    })
    .addCase(getType.fulfilled, (state, action) => {
      state.typeChoice = action.payload.typeChoice
      state.paramsType = action.payload.paramsType
    })
  }
})

export default typeSlice.reducer
