// ** Reactstrap Imports
import { Check, Info } from 'react-feather'
import toast from 'react-hot-toast'
import { Card, CardHeader, CardBody, CardTitle, Button, CardText } from 'reactstrap'

// ** Third Party Components
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

// ** Custom Components
import Avatar from '@components/avatar'

const MySwal = withReactContent(Swal)

export const toastSuccess = (text) => {
  toast(
    <div className='d-flex'>
      <div className='me-1'>
        <Avatar size='sm' color='success' icon={<Check size={12} />} />
      </div>
      <div className='d-flex flex-column'>
        <h6>Thành công</h6>
        <ul className='list-unstyled mb-0'>
          <li>
            <strong>{text}</strong>
          </li>
        </ul>
      </div>
    </div>
  )
}

export const toastWarning = (text) => {
  toast(
    <div className='d-flex'>
      <div className='me-1'>
        <Avatar size='sm' color='warning' icon={<Info size={12} />} />
      </div>
      <div className='d-flex flex-column'>
        <h6>Chú ý</h6>
        <ul className='list-unstyled mb-0'>
          <li>
            <div dangerouslySetInnerHTML={{__html: text}}></div>
          </li>
        </ul>
      </div>
    </div>
  )
}

export const handleSuccess = (title, text) => {
    return MySwal.fire({
      title,
      text,
      icon: 'success',
      timer: '3000',
      customClass: {
        confirmButton: 'btn btn-primary'
      },
      buttonsStyling: false
    })
  }

export const  handleInfo = () => {
    return MySwal.fire({
      title: 'Info!',
      text: 'You clicked the button!',
      icon: 'info',
      customClass: {
        confirmButton: 'btn btn-primary'
      },
      buttonsStyling: false
    })
  }

export const handleWarning = (title, html) => {
    return MySwal.fire({
      title,
      html,
      icon: 'warning',
      customClass: {
        confirmButton: 'btn btn-primary'
      },
      buttonsStyling: false
    })
  }

export const handleError = () => {
    return MySwal.fire({
      title: 'Error!',
      text: ' You clicked the button!',
      icon: 'error',
      customClass: {
        confirmButton: 'btn btn-primary'
      },
      buttonsStyling: false
    })
  }

export const handleQuestion = (title, text, callback) => {
    return MySwal.fire({
      title,
      text,
      icon: 'question',
      customClass: {
        confirmButton: 'me-1'
      },
      // buttonsStyling: true,
      showCancelButton: true,
      confirmButtonText: 'Tiếp tục',
      cancelButtonText: 'Quay lại',
      confirmButtonColor: '#dd000d'
    })
    .then(confirm => {
        console.log(confirm)
        if (confirm.isConfirmed) callback(confirm.isConfirmed)
    })
  }

export const handleLoading = async (text) => {
    let timerInterval
    return MySwal.fire({
      // title: 'Vui lòng đợi',
      html: text,
      timerProgressBar: true,
      // timer: 2000,
      allowOutsideClick: false,
      didOpen() {
        Swal.showLoading()
      },
      willClose() {
        clearInterval(timerInterval)
      }
    }).then(function (result) {
      if (result.dismiss === Swal.DismissReason.timer) {
        // console.log('I was closed by the timer')
      }
    })
  }

export const closeSwal = () => {
  MySwal.close()
}