// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import useJwt from '@src/auth/jwt/useJwt'

// ** Axios Imports
import axios from 'axios'
import { closeSwal, handleError, handleLoading, toastSuccess } from '../../sweetalert'

export const getData = createAsyncThunk('unit/getData', async params => {
  try {
    params.setIsLoading(true)
    const response = await useJwt.getListUnit(params)
    params.setIsLoading(false)
    return { 
      allData: response.data.data, 
      data: response.data.data, 
      totalPages: 1, 
      params 
    }
    
  } catch (error) {
    useJwt.handleError(error)
    params.setIsLoading(false)
    return {
      allData: [], 
      data: [], 
      totalPages: 1, 
      params 
    }
  }
})

export const getUnit = createAsyncThunk('unit/getUnit', async params => {
  try {
    params.setBlock(true)
    const response = await useJwt.getUnit(params)
    params.setBlock(false)
    return { 
      unitChoice: response.data.data, 
    }
  } catch (error) {
    useJwt.handleError(error)
    return {
      unitChoice: response.data.data
    }
  }
})

export const createUnit = createAsyncThunk('unit/create', async params => {
  try {
    params.setShowLoading(true)
    await useJwt.createUnit(params.data)
    toastSuccess("Tạo thành công")
    params.setShowLoading(false)
    if (!params.checkCreate) {
      params.navigateFunction()
    }
    params.reset()
    closeSwal()
  } catch (error) {
    setTimeout(() => {
      closeSwal()
      useJwt.handleError(error)
    }, 1000)
  }
})

export const editUnit = createAsyncThunk('unit/edit', async params => {
  try {
    params.setShowLoading(true)
    await useJwt.editUnit(params.data)
    params.setShowLoading(false)
    toastSuccess("Chỉnh sửa thành công")
    if (params.checkCreate) {
        params.navigateFunction()
    }
  } catch (error) {
    params.setShowLoading(false)
    useJwt.handleError(error)
    params.reset()
  }
})

export const deleteUnit = createAsyncThunk('unit/delete', async (params, {getState, dispatch}) => {
  try {
    handleLoading("Vui lòng chờ hệ thống...")
    await useJwt.deleteUnit(params)
    const store = getState().unit
    await dispatch(getData(store.params))
    closeSwal()
    toastSuccess("Xóa thành công")
  } catch (error) {
    closeSwal()
    useJwt.handleError(error)
  }
})

export const unitSlice = createSlice({
  name: 'unit',
  initialState: {
    data: [],
    total: 1,
    params: {},
    allData: [],
    unitChoice: {}
  },
  reducers: {},
  extraReducers: builder => {
    builder.addCase(getData.fulfilled, (state, action) => {
      state.data = action.payload.data
      state.params = action.payload.params
      state.allData = action.payload.allData
      state.total = action.payload.totalPages
    })
    .addCase(getUnit.fulfilled, (state, action) => {
      state.unitChoice = action.payload.unitChoice
    })
  }
})

export default unitSlice.reducer
