// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import useJwt from '@src/auth/jwt/useJwt'
import { closeSwal, handleError, handleLoading, toastSuccess } from '../../sweetalert'

export const getData = createAsyncThunk('paymentMethod/getData', async params => {
  try {
    if (params.setIsLoading !== null && params.setIsLoading !== undefined)
      params?.setIsLoading(true)
    const response = await useJwt.getListPaymentMethods(params)
    if (params.setIsLoading !== null && params.setIsLoading !== undefined)
      params?.setIsLoading(false)
    return { 
      allData: response.data.data, 
      data: response.data.data, 
      totalPages: response.data.total_page, 
      params 
    }
    
  } catch (error) {
    useJwt.handleError(error)
    if (params.setIsLoading !== null && params.setIsLoading !== undefined)
      params?.setIsLoading(false)
    return {
      allData: [], 
      data: [], 
      totalPages: 1, 
      params 
    }
  }
})

export const getPaymentMethod = createAsyncThunk('paymentMethod/getPaymentMethod', async params => {
  try {
    params.setBlock(true)
    const response = await useJwt.getPaymentMethod(params)
    params.setBlock(false)
    return { 
      paymentMethodChoice: response.data.data, 
      paramsPaymentMethod: params
    }
  } catch (error) {
    useJwt.handleError(error)
    return {
      paymentMethodChoice: {},
      paramsPaymentMethod: params
    }
  }
})

export const createPaymentMethod = createAsyncThunk('paymentMethod/create', async (params, {dispatch, getState}) => {
  try {
    params.setBlock(true)
    await useJwt.addPaymentMethod(params.data)
    params.setBlock(false)
    toastSuccess("Tạo thành công")
    if (!params.checkCreate) {
      params.navigateFunction()
    }
    // if (params.isReload !== undefined && params.isReload === true) {
    //   dispatch(getData(
    //     getState().tag.params
    //   ))
    // }
    params.reset()
  } catch (error) {
    useJwt.handleError(error)
  }
})

export const editPaymentMethod = createAsyncThunk('paymentMethod/edit', async (params, {dispatch, getState}) => {
  try {
    params.setBlock(true)
    const response = await useJwt.editPaymentMethod(params.data)
    params.setBlock(false)
    toastSuccess("Chỉnh sửa thành công")
    if (params.checkCreate) {
        params.navigateFunction()
    }
    dispatch(
      getPaymentMethod(getState().paymentMethod.paramsPaymentMethod)
    )
  } catch (error) {
    closeSwal()
    useJwt.handleError(error)
    params.reset()
  }
})

export const deletePaymentMethod = createAsyncThunk('paymentMethod/delete', async (params, {getState, dispatch}) => {
  try {
    handleLoading("Vui lòng chờ hệ thống...")
    await useJwt.deletePaymentMethod(params)
    const store = getState().paymentMethod
    await dispatch(getData(store.params))
    closeSwal()
    toastSuccess("Xóa thành công")
  } catch (error) {
    closeSwal()
    useJwt.handleError(error)
  }
})

export const paymentMethodSlice = createSlice({
  name: 'paymentMethod',
  initialState: {
    data: [],
    total: 1,
    params: {},
    allData: [],
    paymentMethodChoice: {},
    paramsPaymentMethod: {}
  },
  reducers: {},
  extraReducers: builder => {
    builder.addCase(getData.fulfilled, (state, action) => {
      state.data = action.payload.data
      state.params = action.payload.params
      state.allData = action.payload.allData
      state.total = action.payload.totalPages
    })
    .addCase(getPaymentMethod.fulfilled, (state, action) => {
      state.paymentMethodChoice = action.payload.paymentMethodChoice
      state.paramsPaymentMethod = action.payload.paramsPaymentMethod
    })
  }
})

export default paymentMethodSlice.reducer
