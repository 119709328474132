import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { Action } from "@remix-run/router";
import useJwt from '@src/auth/jwt/useJwt'
import { setUserData } from "../../../../redux/authentication";
import { closeSwal, handleLoading, toastSuccess } from "../../foster/sweetalert";

export const getData = createAsyncThunk('account/getData', async (params, {dispatch}) => {
  try {
    console.log("ok")
    params.setShowLoading(true)
    const response = await useJwt.getMe(params)
    console.log("response", response)
    const userData = {
      ability: [
        {
          action: 'manage',
          subject: 'all'
        }
      ],
      extras: {
        eCommerceCartItemsCount: 5
      },
      ...response.data.data,
      avatar: (response.data.data.avatar_url !== "") ? response.data.data.avatar_url : require('@src/assets/images/portrait/small/avatar-s-11.jpg').default,
      avatar_url: (response.data.data.avatar_url !== "") ? response.data.data.avatar_url : require('@src/assets/images/portrait/small/avatar-s-11.jpg').default,
    }
    localStorage.setItem(useJwt.jwtConfig.storageUserDataKeyName, JSON.stringify(userData))
    dispatch(setUserData(
      userData
    ))
    params.setShowLoading(false)
    return {
      userData: userData,
      params
    }
  } catch (error) {
    params.setShowLoading(false)
    useJwt.handelError(error)
    return {
      userData: [],
      params
    }
  }
})

export const updateInfoMe = createAsyncThunk('account/updateMe', async (params, {dispatch, getState}) => {
  try {
    handleLoading("Vui lòng chờ hệ thống...")
    await useJwt.updateInfoMe({
      data: {
        ...params.data,
        full_name: params.data.full_name,
        dob: new Date(params.data.dob),
        gender: params.data.gender?.value,
        phone: params.data.phone
      }
    })
    if (params.avatar !== null && params.avatar !== undefined)
      await useJwt.updateAvatarMe({
        data: params.avatar
      })
    toastSuccess("Cập nhật thành công")
    await dispatch(
      getData(getState().account.params)
    )
    closeSwal()
  } catch (error) {
    closeSwal()
    useJwt.handleError(error)
  }
})

export const updatePassword = createAsyncThunk('account/updatePassword', async (params) => {
  try {
    params.setShowLoading(true)
    console.log("aaa", params)
    await useJwt.updatePassword({
      data: {
        ...params.data
      }
    })
    toastSuccess("Cập nhật thành công")
    params.setShowLoading(false)
    params.reset()
  } catch (error) {
    params.setShowLoading(false)
    useJwt.handleError(error)
  }
})

export const deleteAccount = createAsyncThunk('account/deleteAccount', async (params) => {
  try {
    handleLoading("Vui lòng chở hệ thống...")
    await useJwt.updatePassword({
      data: {
        ...params.data
      }
    })
    toastSuccess("Cập nhật thành công")
    closeSwal()
    params.reset()
  } catch (error) {
    closeSwal()
    useJwt.handleError(error)
  }
})

export const accountSlice = createSlice({
  name: 'account',
  initialState: {
    userData: {}
  },
  reducers: {

  },
  extraReducers: builder => {
    builder.addCase(getData.fulfilled, (state, action) => {
      return {
        ...state,
        ...action.payload.userData,
        params: action.payload.params
      }
    })
  }
})

export default accountSlice.reducer